import { z } from "zod";
export const ZPlanetDetail = z.object({
    planet_name: z.string(),
    sign_name: z.string(),
    house_position: z.number(),
});
export const ZLegacyChart = z.object({
    sun: ZPlanetDetail,
    moon: ZPlanetDetail,
    mercury: ZPlanetDetail,
    venus: ZPlanetDetail,
    mars: ZPlanetDetail,
    jupiter: ZPlanetDetail,
    saturn: ZPlanetDetail,
    uranus: ZPlanetDetail,
    neptune: ZPlanetDetail,
    ascendant: ZPlanetDetail,
    wheel: z.string().optional(),
});
export const ZLegacyVedicChart = z.object({
    sun: ZPlanetDetail,
    moon: ZPlanetDetail,
    mars: ZPlanetDetail,
    mercury: ZPlanetDetail,
    jupiter: ZPlanetDetail,
    venus: ZPlanetDetail,
    saturn: ZPlanetDetail,
    rahu: ZPlanetDetail,
    ketu: ZPlanetDetail,
    lagna: ZPlanetDetail,
});
const ZPlanetName = z.union([
    z.literal("Sun"),
    z.literal("Moon"),
    z.literal("Mercury"),
    z.literal("Venus"),
    z.literal("Mars"),
    z.literal("Jupiter"),
    z.literal("Saturn"),
    z.literal("Uranus"),
    z.literal("Neptune"),
    z.literal("Pluto"),
]);
const ZPlanetKey = z.union([
    z.literal("sun"),
    z.literal("moon"),
    z.literal("mercury"),
    z.literal("venus"),
    z.literal("mars"),
    z.literal("jupiter"),
    z.literal("saturn"),
    z.literal("uranus"),
    z.literal("neptune"),
    z.literal("pluto"),
]);
const ZPointName = z.union([
    z.literal("Ascendant"),
    z.literal("North Node"),
    z.literal("South Node"),
    z.literal("Chiron"),
    z.literal("Fortune"),
    z.literal("Lilith"),
    z.literal("Midheaven"),
    z.literal("Vertex"),
]);
const ZPointKey = z.union([
    z.literal("ascendant"),
    z.literal("northNode"),
    z.literal("southNode"),
    z.literal("chiron"),
    z.literal("fortune"),
    z.literal("lilith"),
    z.literal("midheaven"),
    z.literal("vertex"),
]);
const ZSignName = z.union([
    z.literal("Aries"),
    z.literal("Taurus"),
    z.literal("Gemini"),
    z.literal("Cancer"),
    z.literal("Leo"),
    z.literal("Virgo"),
    z.literal("Libra"),
    z.literal("Scorpio"),
    z.literal("Sagittarius"),
    z.literal("Capricorn"),
    z.literal("Aquarius"),
    z.literal("Pisces"),
]);
const ZHouseName = z.enum([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]);
const ZPlanet = z.object({
    name: ZPlanetName,
    sign: ZSignName,
    house: z.number().min(1).max(12),
    longitude: z.number(),
    degrees: z.number(),
    speed: z.number(),
    retrograde: z.boolean(),
});
const ZPoint = z.object({
    name: ZPointName,
    sign: ZSignName,
    house: z.number().min(1).max(12).optional(),
    longitude: z.number().optional(),
    degrees: z.number(),
    speed: z.number().optional(),
    retrograde: z.boolean().optional(),
});
const ZHouse = z.object({
    house: z.number().min(1).max(12),
    planets: z.array(ZPlanetName),
    sign: ZSignName,
    ruler: z.string(),
    longitude: z.number(),
    degrees: z.number(),
});
const ZAspectType = z.union([
    z.literal("trine"),
    z.literal("conjunction"),
    z.literal("inconjunct"),
    z.literal("sextile"),
    z.literal("square"),
    z.literal("opposition"),
    z.literal("opposite"),
    z.literal("semisquare"),
    z.string(), // For additional custom aspect types
]);
const ZAspect = z.object({
    planet1: z.string(),
    planet2: z.string(),
    type: ZAspectType,
    longitude: z.number(),
});
export const ZChart = z.object({
    planets: z.record(ZPlanetKey, ZPlanet),
    points: z.record(ZPointKey, ZPoint).optional(),
    houses: z.record(ZHouseName, ZHouse),
    ascendant: z.object({
        sign: ZSignName,
    }),
    aspects: z.array(ZAspect),
    wheel: z.string().optional(),
});
export const ZChartTexts = z.object({
    full: z.string().optional(),
    planets: z.string().optional(),
    aspects: z.string().optional(),
    points: z.string().optional(),
    cusps: z.string().optional(),
    birthData: z.string().optional(),
    chartTypeTitle: z.string().optional(),
});

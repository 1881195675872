import { PRODUCTS } from "@luaroxa/contracts"
import type { RouteRecordRaw } from "vue-router"

import { redirectLegacyToNew } from "../helpers"

redirectLegacyToNew()

function generateAdminRoutes(
  products: Record<string, string>,
): RouteRecordRaw[] {
  const map = Object.entries(products).flatMap(
    ([key, product]): RouteRecordRaw[] => {
      const keyLower = key.toLowerCase().replace(/_/g, "-")
      return [
        {
          path: `/:bloggerId/${keyLower}-admin`,
          redirect: `/:bloggerId/admin/${keyLower}`,
        },
        {
          path: `/:bloggerId/admin/${keyLower}`,
          name: `/:bloggerId/admin-${keyLower}`,
          component: () => import("@/views/quiz/AdminQuizView.vue"),
          props: { product },
        },
      ]
    },
  )

  Object.freeze(map)
  return map
}

const adminRoutes = generateAdminRoutes(PRODUCTS)

export default adminRoutes

export const bloggerTranslations = {
    Astrology: {
        pt: "Astrologia",
        es: "Astrología",
        ru: "Астрология",
        en: "Astrology",
    },
    "Found:": {
        pt: "Encontrado:",
        es: "Encontrado:",
        ru: "Найдено:",
        en: "Found:",
    },
    Logout: {
        pt: "Sair",
        es: "Cerrar sesión",
        ru: "Выйти",
        en: "Logout",
    },
    Branch: {
        pt: "Ramo",
        es: "Rama",
        ru: "Ветка",
        en: "Branch",
    },
    Product: {
        pt: "Produto",
        es: "Producto",
        ru: "Продукт",
        en: "Product",
    },
    Price: {
        pt: "Preço",
        es: "Precio",
        ru: "Цена",
        en: "Price",
    },
    Variation: {
        pt: "Variação",
        es: "Variación",
        ru: "Вариация",
        en: "Variation",
    },
    Orders: {
        pt: "Pedidos",
        es: "Pedidos",
        ru: "Заказы",
        en: "Orders",
    },
    Days: {
        pt: "Dias",
        es: "Días",
        ru: "Дни",
        en: "Days",
    },
    Columns: {
        pt: "Colunas",
        es: "Columnas",
        ru: "Колонки",
        en: "Columns",
    },
    "Add Filter": {
        pt: "Filtro",
        es: "Filtro",
        ru: "Фильтр",
        en: "Filter",
    },
    PaymentSystem: {
        pt: "Sistema de pagamento",
        es: "Sistema de pago",
        ru: "Платежная система",
        en: "Payment System",
    },
    "Apply Filters": {
        pt: "Aplicar filtros",
        es: "Aplicar filtros",
        ru: "Применить фильтры",
        en: "Apply Filters",
    },
    Cancel: {
        pt: "Cancelar",
        es: "Cancelar",
        ru: "Отмена",
        en: "Cancel",
    },
    "Add UTM Filters": {
        pt: "Adicionar filtros UTM",
        es: "Agregar filtros UTM",
        ru: "Добавить UTM фильтры",
        en: "Add UTM Filters",
    },
    "direct sale": {
        pt: "venda direta",
        es: "venta directa",
        ru: "прямая продажа",
        en: "direct sale",
    },
    "Direct sale": {
        pt: "Venda direta",
        es: "Venta directa",
        ru: "Прямая продажа",
        en: "Direct sale",
    },
    Date: {
        pt: "Data",
        es: "Fecha",
        ru: "Дата",
        en: "Date",
    },
    "Filter by date": {
        pt: "Filtrar por data",
        es: "Filtrar por fecha",
        ru: "Фильтровать по дате",
        en: "Filter by date",
    },
    "Filter by Email": {
        pt: "Filtrar por email",
        es: "Filtrar por email",
        ru: "Фильтровать по email",
        en: "Filter by Email",
    },
    "Exact match": {
        pt: "Correspondência exata",
        es: "Coincidencia exacta",
        ru: "Точное совпадение",
        en: "Exact match",
    },
    "Enter exact email": {
        pt: "Digite o email exato",
        es: "Introduce el email exacto",
        ru: "Введите точный email",
        en: "Enter exact email",
    },
    Contains: {
        pt: "Contém",
        es: "Contiene",
        ru: "Содержит",
        en: "Contains",
    },
    "Enter substring of email": {
        pt: "Digite o substring do email",
        es: "Introduce el substring del email",
        ru: "Введите часть email",
        en: "Enter substring of email",
    },
    "Copied to clipboard": {
        pt: "Copiado",
        es: "Copiado",
        ru: "Скопировано",
        en: "Copied",
    },
    Phone: {
        pt: "Telefone",
        es: "Teléfono",
        ru: "Телефон",
        en: "Phone",
    },
};

import { z } from "zod";
import { LANGUAGE } from "./language.enum";
export const ZRegistrationRequest = z.object({
    email: z.string().email(),
    password: z.string(),
});
export const ZLoginRequest = z.object({
    email: z.string().email(),
    password: z.string(),
});
export const ZSendVerificationRequest = z.object({
    email: z.string().email(),
    code: z.string().optional(), // Telegram bot has it's own code format
    language: z.nativeEnum(LANGUAGE).optional(),
});
export const ZVerifyEmailRequest = z.object({
    email: z.string().email(),
    code: z.string(),
});
export const ZResetPasswordRequest = z.object({
    email: z.string().email(),
    code: z.string(),
    password: z.string(),
});
export const ZSetPassswordRequest = z.object({
    cacheId: z.string(),
    password: z.string(),
});
export const ZCreateTokenRequest = z.object({
    email: z.string().email(),
    userId: z.string(),
    days: z.number(),
});

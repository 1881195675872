import {
  BRAND,
  type IChart,
  type IChartTexts,
  type ILegacyChart,
  type IVedicChart,
  LANGUAGE,
  type ProductType,
  type QuizCustomizations,
  type VariationType,
} from "@luaroxa/contracts"
import { getUtmParameters } from "@luaroxa/shared-vue"
import { defineStore } from "pinia"
import { v4 as uuidv4 } from "uuid"
import { useState as useGTag } from "vue-gtag-next"

import { LANG } from "@/helpers"
import { isPaymentScreen } from "@/helpers/payment-pages"

export interface IFlowState {
  bloggerId: string
  productId: Maybe<ProductType>
  variationId: Maybe<VariationType>
  sessionId: string
  clientId: string
  paidEarlierId: string
  abTestId: string
  person: {
    natalChart: Maybe<ILegacyChart>
    natalChartText: Maybe<string>
    natalVedicChart: Maybe<IVedicChart>
    natalVedicChartText: Maybe<string>
  }
  partner: {
    natalChart: Maybe<ILegacyChart>
    natalChartText: Maybe<string>
    natalVedicChart: Maybe<IVedicChart>
    natalVedicChartText: Maybe<string>
  }
  solarReturn: {
    chart: Maybe<IChart>
    chartTexts: Maybe<IChartTexts>
  }
  demoInterpretation: string
  language: Maybe<LANGUAGE>
  customizations: Maybe<QuizCustomizations>
  userId: string
  deliveryDays: Maybe<number>
  utm: Maybe<string>
  directSale: boolean
}

const defaults: IFlowState = {
  bloggerId: null,
  productId: null,
  variationId: null,
  sessionId: null,
  clientId: null,
  paidEarlierId: "",
  abTestId: "",
  person: {
    natalChart: null,
    natalChartText: "",
    natalVedicChart: null,
    natalVedicChartText: "",
  },
  partner: {
    natalChart: null,
    natalChartText: "",
    natalVedicChart: null,
    natalVedicChartText: "",
  },
  solarReturn: {
    chart: null,
    chartTexts: null,
  },
  demoInterpretation: "",
  language: LANGUAGE.PT,
  customizations: null,
  userId: null,
  deliveryDays: null,
  utm: null,
  directSale: false,
}

const resets = {
  bloggerId: "",
  productId: null,
  variationId: null,
  paidEarlierId: "",
  abTestId: "",
  demoInterpretation: "",
  language: null,
  customizations: null,
  deliveryDays: null,
  directSale: false,
}

function composeState(saved: Partial<IFlowState>): IFlowState {
  const resetState = !isPaymentScreen()

  const state = {
    ...defaults,
    ...saved,
    ...(resetState && resets),
  }

  if (!state.clientId) {
    state.clientId = uuidv4()
  }
  state.language = LANG

  if (!state.utm) {
    state.utm = getUtmParameters()
  }
  return state
}

const localStorageKey = "pinia/flow/1"

export const useFlowStore = defineStore("flow", () => {
  // remove legacy data
  localStorage.removeItem("pinia/flow")

  const { property: gtagProperty, isEnabled: gtagEnabled } = useGTag()

  const saved = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey) || "")
    : {}

  const state = reactive<IFlowState>(composeState(saved))
  watch(
    () => state,
    (state) => {
      localStorage.setItem(localStorageKey, JSON.stringify(state))
    },
    { deep: true },
  )

  const setProductId = (product: ProductType) => {
    state.productId = product
    return state.productId
  }

  const setVariationId = (variation: VariationType) => {
    state.variationId = variation
    return state.variationId
  }

  const setQuizCustomizations = (customizations: QuizCustomizations) => {
    state.customizations = customizations
    return state.customizations
  }

  const setMetaTitle = (title: string | null | undefined) => {
    if (!title) {
      document.title = [LANGUAGE.ES, LANGUAGE.RU].includes(state.language)
        ? "Zodiaque"
        : "Lua Roxa"
      return
    }
    document.title = title
  }

  function setSessionId(id: string) {
    state.sessionId = id

    gtagProperty.value = {
      id: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
      params: {
        clientId: state.clientId,
        ...(state.userId && { userId: state.userId }),
      },
    }

    gtagEnabled.value = true
  }

  return {
    flowState: state,
    language: computed(() => state.language),
    brand: computed(() =>
      state.language === LANGUAGE.PT ? BRAND.LUA_ROXA : BRAND.ZODIAQUE,
    ),
    userId: computed(() => state.userId),
    setProductId,
    setVariationId,
    setQuizCustomizations,
    setPaidEarlierId: (value: string) => (state.paidEarlierId = value),
    setAbTestId: (value: string) => (state.abTestId = value),
    setSessionId,
    setUserId: (id: string) => (state.userId = id),
    setBloggerId: (id: string) => (state.bloggerId = id),
    setMetaTitle,
    setDeliveryDays: (days: number) => (state.deliveryDays = days),
    setDirectSale: (value: boolean) => (state.directSale = value),
  }
})

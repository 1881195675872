import { LANGUAGE } from "../language.enum";
import { bloggerTranslations } from "./blogger-translations";
import { chartTranslations } from "./chart-translations";
import { quizOfferTranslations } from "./quiz-offer-translations";
import { quizTranslations } from "./quiz-translations";
export const defaultDictionary = {
    ...quizTranslations,
    ...quizOfferTranslations,
    ...chartTranslations,
    ...bloggerTranslations,
};
export function translate(key, language, variables = {}, dictionary = defaultDictionary, variation = "default") {
    const template = dictionary[key]?.[language];
    if (!template) {
        return null;
    }
    let result = typeof template === "object" && template[variation]
        ? template[variation]
        : template;
    const vars = dictionary[key].variables ?? [];
    if (!vars) {
        return result;
    }
    vars.forEach((variable) => {
        const placeholder = `{{${variable}}}`;
        const value = variables[variable];
        if (value !== undefined) {
            result = result.replaceAll(new RegExp(placeholder, "g"), value);
        }
    });
    return result;
}
export const languageToLocaleId = {
    [LANGUAGE.PT]: "pt-BR",
    [LANGUAGE.ES]: "es-ES",
    [LANGUAGE.RU]: "ru-RU",
    [LANGUAGE.EN]: "en-US",
};

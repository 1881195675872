import { z } from "zod";
import { ZPerson } from "./natal-chart.interfaces";
export const ZForecast = z.object({
    months: z.array(z.string()),
    year: z.string(),
});
export const ZForecast2024Json = z.object({
    person: ZPerson,
    forecast: ZForecast,
});

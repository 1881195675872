import { z } from "zod";
import { LANGUAGE } from "./language.enum";
import { ZPerson } from "./natal-chart.interfaces";
import { ZQuizBaseRequest, ZQuizStartRequest } from "./quiz.interfaces";
import { ZLegacyChart } from "./WesternAstrology/western-astrology.chart.interfaces";
export const ZDailyCreateReportRequest = z.object({
    userId: z.string(),
    email: z.string().email().optional(),
});
export const ZDailyFindReportRequest = z.object({
    userId: z.string(),
});
export const ZDailyQuizStartRequest = ZQuizStartRequest.extend({
    userId: z.string().optional(),
});
export const ZPresaveDailyQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string(),
    userData: z.object({
        email: z.string().email(),
        phone: z.string().optional(),
    }),
});
export const ZDailyReportPayload = z.object({
    date: z.string(), // should be a string as it's used in the GPT prompt
    person: ZPerson,
    yearEvents: z.string(),
    moonReport: z.record(z.string(), z.any()),
    answers: z.record(z.string(), z.string()),
    planets: ZLegacyChart.omit({ wheel: true }).required(),
});
export const ZSubmitDailyQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string().optional(),
    userId: z.string(),
});
export const ZDailyReport = z.object({
    userId: z.string(),
    report: z.string(),
    language: z.nativeEnum(LANGUAGE),
    createdAt: z.date(),
    payload: ZDailyReportPayload,
});

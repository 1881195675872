import { z } from "zod";
import { ZBirthData, ZGender, ZName, ZQuizBaseRequest, ZQuizRequestBaseMetadata, } from "./quiz.interfaces";
import { ZVedicChart, ZVedicChartTexts, } from "./VedicAstrology/vedic-astrology.chart.interfaces";
import { ZChart, ZChartTexts, ZLegacyChart, } from "./WesternAstrology/western-astrology.chart.interfaces";
export const ZPerson = ZName.extend({
    birthData: ZBirthData,
    gender: ZGender.optional(),
    natalChart: ZLegacyChart.optional(),
    natalChartText: z.string().optional(),
    chart: ZChart.optional(),
    chartTexts: ZChartTexts.optional(),
    vedicChart: ZVedicChart.optional(),
    vedicChartTexts: ZVedicChartTexts.optional(),
});
export const ZSolarReturnResponse = z.object({
    chart: ZChart,
    chartTexts: ZChartTexts,
    year: z.number(),
});
export const ZChartRequest = ZQuizBaseRequest.extend({
    person: ZPerson.optional(),
    partner: ZPerson.optional(),
    solarYear: z.number().optional(),
    metadata: ZQuizRequestBaseMetadata,
});
export const ZAstrology = z.enum(["western", "vedic"]);
export var Astrology;
(function (Astrology) {
    Astrology["WESTERN"] = "western";
    Astrology["VEDIC"] = "vedic";
})(Astrology || (Astrology = {}));
export function getRulerBySign(sign) {
    const rulers = {
        Aries: "Mars",
        Taurus: "Venus",
        Gemini: "Mercury",
        Cancer: "Moon",
        Leo: "Sun",
        Virgo: "Mercury",
        Libra: "Venus",
        Scorpio: "Mars",
        Sagittarius: "Jupiter",
        Capricorn: "Saturn",
        Aquarius: "Saturn",
        Pisces: "Jupiter",
    };
    return rulers[sign];
}

export const quizTranslations = {
    Hi: {
        variables: ["userName"],
        pt: "Oi {{userName}}",
        es: "Hola {{userName}}",
        ru: "Привет {{userName}}",
        en: "Hi {{userName}}",
    },
    GenderQuestion: {
        pt: "Seu gênero:",
        es: "Tu género:",
        ru: "Ваш пол:",
        en: "Your gender:",
    },
    PartnerGenderQuestion: {
        pt: "Gênero do seu parceiro:",
        es: "Género de tu pareja:",
        ru: "Пол вашего партнера:",
        en: "Your partner gender:",
    },
    male: {
        pt: "Homem",
        es: "Hombre",
        ru: "Мужчина",
        en: "Male",
    },
    female: {
        pt: "Mulher",
        es: "Mujer",
        ru: "Женщина",
        en: "Female",
    },
    "non-binary": {
        pt: "Não-binário",
        es: "No binario",
        ru: "Не бинарный",
        en: "Non-binary",
    },
    RelationshipStatusQuestion: {
        pt: "Para que possamos conhecer você melhor, conte-nos sobre a situação do seu relacionamento.",
        es: "Para que podamos conocerte mejor, cuéntanos sobre la situación de tu relación.",
        ru: "Чтобы мы могли лучше вас понять, расскажите нам о ваших отношениях.",
        en: "To get to know you better, tell us about your relationship status.",
    },
    Single: {
        pt: "Solteiro (a)",
        es: "Soltero (a)",
        ru: "Одинокий",
        en: "Single",
    },
    InRelationship: {
        pt: "Em um relacionamento",
        es: "En una relación",
        ru: "В отношениях",
        en: "In a relationship",
    },
    HasChildrenQuestion: {
        pt: "Você tem filhos?",
        es: "¿Tienes hijos?",
        ru: "У вас есть дети?",
        en: "Do you have children?",
    },
    Yes: {
        pt: "Sim",
        es: "Sí",
        ru: "Да",
        en: "Yes",
    },
    No: {
        pt: "Não",
        es: "No",
        ru: "Нет",
        en: "No",
    },
    RelationshipGoalQuestion: {
        pt: "Qual é o seu principal objetivo em um relacionamento?",
        es: "¿Cuál es tu objetivo principal en una relación?",
        ru: "Какова ваша главная цель в отношениях?",
        en: "What is your main goal in a relationship?",
    },
    MoreSatisfactionInRelationship: {
        pt: "Ter maior satisfação no relacionamento",
        es: "Tener más satisfacción en la relación",
        ru: "Иметь больше удовлетворения от отношений",
        en: "Have more relationship satisfaction",
    },
    FixRelationshipProblems: {
        pt: "Corrigir os problemas do relacionamento",
        es: "Resolver problemas de relación",
        ru: "Решить проблемы отношений",
        en: "Fix relationship problems",
    },
    BuildStrongMarriage: {
        pt: "Construir um casamento sólido",
        es: "Construir un matrimonio sólido",
        ru: "Построить крепкий брак",
        en: "Build a strong marriage",
    },
    VerifyCompatibility: {
        pt: "Verificar a compatibilidade",
        es: "Verificar compatibilidad",
        ru: "Проверить совместимость",
        en: "Verify compatibility",
    },
    AllOfTheAbove: {
        pt: "Todos os anteriores",
        es: "Todos los anteriores",
        ru: "Все вышеперечисленное",
        en: "All of the above",
    },
    WorkStatusQuestion: {
        pt: "Você tem um emprego/negócio?",
        es: "¿Tienes un trabajo/negocio?",
        ru: "У вас есть работа/бизнес?",
        en: "Do you have a job/business?",
    },
    HaveJob: {
        pt: "Sim, tenho um emprego",
        es: "Sí, tengo un trabajo",
        ru: "Да, у меня есть работа",
        en: "Yes, I have a job",
    },
    HaveBusiness: {
        pt: "Sim, tenho um negócio",
        es: "Sí, tengo un negocio",
        ru: "Да, у меня есть бизнес",
        en: "Yes, I have a business",
    },
    YearGoalQuestion: {
        pt: "Quais são suas principais metas este ano?",
        es: "¿Cuáles son tus principales metas este año?",
        ru: "Какие ваши основные цели на этот год?",
        en: "What are your main goals this year?",
    },
    ImproveRelationship: {
        pt: "Melhorar os relacionamentos",
        es: "Mejorar las relaciones",
        ru: "Улучшить отношения",
        en: "Improve relationships",
    },
    IncreaseIncome: {
        pt: "Aumentar a renda",
        es: "Incrementar los ingresos",
        ru: "Увеличить доход",
        en: "Increase income",
    },
    KnowMyselfBetter: {
        pt: "Conhecer-se melhor",
        es: "Conocerse mejor",
        ru: "Лучше узнать себя",
        en: "Get to know myself better",
    },
    ProgressInCareer: {
        pt: "Obter progresso na carreira",
        es: "Obtener progreso en la carrera",
        ru: "Добиться успеха в карьере",
        en: "Get a career progress",
    },
    GetEductation: {
        pt: "Obter uma educação",
        es: "Obtener una educación",
        ru: "Получить образование",
        en: "Get an education",
    },
    EnjoyLife: {
        pt: "Simplesmente aproveitar a vida",
        es: "Simplemente disfrutar la vida",
        ru: "Просто наслаждаться жизнью",
        en: "Simply enjoy life",
    },
    Retry: {
        pt: "Tentar novamente",
        es: "Reintentar",
        ru: "Повторить",
        en: "Retry",
    },
    "Your uniqueness": {
        pt: "Você é único",
        es: "Tú eres único",
        ru: "Ты уникален",
        en: "You are unique",
    },
    "Your strengths": {
        pt: "Suas forças",
        es: "Tus fortalezas",
        ru: "Твои сильные стороны",
        en: "Your strengths",
    },
    "Your weaknesses": {
        pt: "Suas fraquezas",
        es: "Tus debilidades",
        ru: "Твои слабости",
        en: "Your weaknesses",
    },
    "Your hidden talents": {
        pt: "Seus talentos ocultos",
        es: "Tus talentos ocultos",
        ru: "Твои скрытые таланты",
        en: "Your hidden talents",
    },
    "Your purpose": {
        pt: "Seu propósito",
        es: "Tu propósito",
        ru: "Твое предназначение",
        en: "Your purpose",
    },
    "Love and compatibility": {
        pt: "Amor e compatibilidade",
        es: "Amor y compatibilidad",
        ru: "Любовь и совместимость",
        en: "Love and compatibility",
    },
    "Mistakes in relationships": {
        pt: "Erros nos relacionamentos",
        es: "Errores en las relaciones",
        ru: "Ошибки в отношениях",
        en: "Mistakes in relationships",
    },
    "Sexual life": {
        pt: "Vida sexual",
        es: "Vida sexual",
        ru: "Сексуальная жизнь",
        en: "Sexual life",
    },
    "Financial prosperity": {
        pt: "Prosperidade financeira",
        es: "Prosperidad financiera",
        ru: "Финансовое процветание",
        en: "Financial prosperity",
    },
    your_uniqueness_description: {
        pt: "Pense em como as estrelas estavam no céu quando você nasceu. Eles contam a história de quem você é. Somos como mágicos, lendo as estrelas e contando o que elas dizem sobre você pessoalmente. É como resolver um enigma sobre como o espaço afetou você.",
        es: "Piensa en cómo estaban las estrellas en el cielo cuando naciste. Cuentan la historia de quién eres. Somos como magos, leemos las estrellas y te contamos lo que dicen de ti personalmente. Es como resolver un acertijo sobre cómo te ha afectado el espacio.",
        ru: "Подумай, как звезды стоят в небе, когда ты родился. Они рассказывают историю того, кто ты есть. Мы как волшебники, читаем звезды и рассказываем тебе, что они говорят о тебе лично. Это как разгадывание загадки о том, как космос повлиял на тебя.",
        en: "Think about how the stars stood in the sky when you were born. They tell the story of who you are. We are like magicians, reading the stars and telling you what they say about you personally. It's like solving a riddle about how space has affected you.",
    },
    your_strengths_description: {
        pt: "Usamos a matemática para entender como os planetas se comunicam quando você nasce. Isso nos ajuda a ver no que você é particularmente bom. É como se rios de energia fluíssem entre os planetas e moldassem seus talentos.",
        es: "Usamos las matemáticas para comprender cómo se comunican los planetas entre sí cuando naces. Esto nos ayuda a ver en qué eres particularmente bueno. Es como si ríos de energía fluyeran entre los planetas y moldearan tus talentos.",
        ru: "Мы используем математику, чтобы понять, как планеты общаются между собой, когда ты родился. Это помогает нам увидеть, в чем ты особенно хорош. Как будто между планетами текут энергетические реки, которые формируют твои таланты.",
        en: "We use mathematics to understand how the planets communicate with each other when you are born. This helps us see what you're particularly good at. It’s as if energy rivers flow between the planets and shape your talents.",
    },
    your_weaknesses_description: {
        pt: "Também estamos analisando onde podem surgir dificuldades. Os planetas criam certas tensões que nos dizem no que você precisa trabalhar. É como um mapa que mostra onde estão os obstáculos e como contorná-los.",
        es: "También estamos analizando dónde pueden surgir dificultades. Los planetas crean ciertas tensiones que nos dicen en qué hay que trabajar. Es como un mapa que muestra dónde están los obstáculos y cómo sortearlos.",
        ru: "Мы тоже смотрим, где могут быть трудности. Планеты создают определенные напряжения, которые говорят нам, над чем тебе стоит поработать. Это как карта, что показывает, где есть препятствия и как их обойти.",
        en: "We are also looking at where difficulties may arise. The planets create certain tensions that tell us what you need to work on. It's like a map that shows where the obstacles are and how to get around them.",
    },
    your_hidden_talents_description: {
        pt: "Ao analisar seu mapa astral, descobrimos talentos que você nem sabia que tinha. Usando as estrelas, ajudamos você a encontrar as habilidades únicas escondidas dentro de você.",
        es: "Al analizar tu carta natal, descubrimos talentos que ni siquiera sabías que tenías. Usando las estrellas, te ayudamos a encontrar las habilidades únicas escondidas en lo más profundo de ti.",
        ru: "Анализируя карту рождения, мы открываем таланты, о которых ты даже не подозревал. Используя звезды, мы помогаем тебе найти уникальные способности, скрытые глубоко в тебе.",
        en: "By analyzing your birth chart, we discover talents that you didn’t even know you had. Using the stars, we help you find the unique abilities hidden deep within you.",
    },
    your_purpose_description: {
        pt: "Olhando para as estrelas, entendemos que lugar você ocupa no grande plano cósmico. É como encontrar o seu caminho em um grande mapa do mundo. Nós ajudamos você a entender para onde ir.",
        es: "Mirando las estrellas, entendemos qué lugar ocupas en el gran plan cósmico. Es como encontrar el camino en un gran mapa del mundo. Te ayudamos a entender a dónde ir.",
        ru: "Смотря на звезды, мы понимаем, какое место ты занимаешь в большом космическом плане. Это как найти свою дорогу на большой карте мира. Мы помогаем тебе понять, куда тебе идти.",
        en: "Looking at the stars, we understand what place you occupy in the grand cosmic plan. It's like finding your way on a big map of the world. We help you understand where to go.",
    },
    love_and_compatibility_description: {
        pt: "Observamos como sua energia se combina com a energia de outras pessoas. É como encontrar alguém com quem você passará a vida de mãos dadas, cuja energia responda à sua.",
        es: "Observamos cómo su energía se combina con la energía de otras personas. Es como encontrar a alguien con quien irás por la vida de la mano, cuya energía responda a la tuya.",
        ru: "Мы смотрим, как твоя энергия сочетается с энергией других людей. Это как найти кого-то, с кем ты будешь идти по жизни рука об руку, чья энергия отзывается твоей.",
        en: "We look at how your energy combines with other people's energy. It's like finding someone with whom you will go through life hand in hand, whose energy responds to yours.",
    },
    mistakes_in_relationships_description: {
        pt: "Às vezes, as estrelas falam sobre o que pode dar errado em um relacionamento. Usamos essas informações para aconselhar sobre como evitar mal-entendidos e fortalecer relacionamentos.",
        es: "A veces las estrellas hablan de lo que puede salir mal en una relación. Utilizamos esta información para dar consejos sobre cómo evitar malentendidos y fortalecer las relaciones.",
        ru: "Иногда звезды говорят о том, что может пойти не так в отношениях. Мы используем эту информацию, чтобы дать советы, как избежать недопонимания и сделать отношения крепче.",
        en: "Sometimes stars talk about what can go wrong in a relationship. We use this information to give advice on how to avoid misunderstandings and make relationships stronger.",
    },
    sexual_life_description: {
        pt: "As estrelas podem dizer muito sobre o que você gosta e o que é importante no amor. Ajudamos você a entender como tornar sua vida pessoal ainda mais brilhante e interessante.",
        es: "Los astros pueden decirte mucho sobre lo que te gusta y lo que es importante en el amor. Le ayudamos a comprender cómo hacer que su vida personal sea aún más brillante e interesante.",
        ru: "Звезды могут многое рассказать о том, что тебе нравится и что важно в любви. Мы помогаем понять, как сделать твою личную жизнь еще ярче и интереснее.",
        en: "The stars can tell you a lot about what you like and what is important in love. We help you understand how to make your personal life even brighter and more interesting.",
    },
    financial_prosperity_description: {
        pt: "Os planetas podem lhe dar dicas sobre como melhorar sua situação financeira. Veremos quais estrelas o ajudarão a ficar mais rico e como usar esse conhecimento na prática.",
        es: "Los planetas pueden darte pistas sobre cómo mejorar tu situación financiera. Analizamos qué estrellas le ayudarán a enriquecerse y cómo utilizar este conocimiento en la práctica.",
        ru: "Планеты могут дать подсказку, как улучшить твое финансовое положение. Мы смотрим, какие звезды помогут тебе стать богаче и как использовать это знание на практике.",
        en: "The planets can give you hints on how to improve your financial situation. We look at which stars will help you become richer and how to use this knowledge in practice.",
    },
    DetermineByName: {
        pt: "determinar pelo nome",
        es: "determinar por nombre",
        ru: "определи по имени",
        en: "determine by name",
    },
    ProfileOnboardingWelcomeMessage: {
        pt: "Bem-vindo à Lua Roxa! Por favor, responda algumas perguntas para completar sua conta",
        es: "¡Bienvenido a Lua Roxa! Por favor, responde algunas preguntas para completar tu cuenta",
        ru: "Добро пожаловать в Lua Roxa! Пожалуйста, ответьте на несколько вопросов, чтобы завершить регистрацию",
        en: "Welcome to Lua Roxa! Please answer a few questions to complete your account",
    },
    Next: {
        pt: "Próximo",
        es: "Siguiente",
        ru: "Далее",
        en: "Next",
    },
    Name: {
        pt: "Nome",
        es: "Nombre",
        ru: "Имя",
        en: "Name",
    },
    FirstName: {
        pt: "Nome",
        es: "Nombre",
        ru: "Имя",
        en: "First Name",
    },
    LastName: {
        pt: "Sobrenome",
        es: "Apellido",
        ru: "Фамилия",
        en: "Last Name",
    },
    DateOfBirth: {
        pt: "Data de Nascimento",
        es: "Fecha de Nacimiento",
        ru: "Дата рождения",
        en: "Date of Birth",
    },
    TimeOfBirth: {
        pt: "Hora de Nascimento",
        es: "Hora de Nacimiento",
        ru: "Время рождения",
        en: "Time of Birth",
    },
    Email: {
        pt: "Email",
        es: "Email",
        ru: "Email",
        en: "Email",
    },
    "Enter email": {
        pt: "Digite o email",
        es: "Ingrese el email",
        ru: "Введите email",
        en: "Enter email",
    },
    "Your email": {
        pt: "Seu email",
        es: "Tu email",
        ru: "Ваш email",
        en: "Your email",
    },
    "Enter last name": {
        pt: "Digite o sobrenome",
        es: "Ingrese el apellido",
        ru: "Введите фамилию",
        en: "Enter last name",
    },
    Password: {
        pt: "Senha",
        es: "Contraseña",
        ru: "Пароль",
        en: "Password",
    },
    "Incorrect code": {
        pt: "Código incorreto",
        es: "Código incorrecto",
        ru: "Неверный код",
        en: "Incorrect code",
    },
    "Enter password": {
        pt: "Digite a senha",
        es: "Ingrese la contraseña",
        ru: "Введите пароль",
        en: "Enter password",
    },
    "Incorrect password": {
        pt: "Senha incorreta",
        es: "Contraseña incorrecta",
        ru: "Неверный пароль",
        en: "Incorrect password",
    },
    "Enter your email for the verification process, we will send the code to your email": {
        pt: "Digite seu email para o processo de verificação, enviaremos o código",
        es: "Ingrese su email para el proceso de verificación, le enviaremos el código",
        ru: "Введите ваш email для процесса верификации, мы отправим код",
        en: "Enter your email for the verification process, we will send the code",
    },
    "Check email": {
        pt: "Verificar email",
        es: "Verificar email",
        ru: "Проверить email",
        en: "Check email",
    },
    "Email verification": {
        pt: "Verificação de email",
        es: "Verificación de email",
        ru: "Верификация email",
        en: "Email verification",
    },
    Confirm: {
        pt: "Confirmar",
        es: "Confirmar",
        ru: "Подтвердить",
        en: "Confirm",
    },
    "Create a new password": {
        pt: "Criar uma nova senha",
        es: "Crear una nueva contraseña",
        ru: "Создать новый пароль",
        en: "Create a new password",
    },
    "Set the new password for your account": {
        pt: "Defina a nova senha para sua conta",
        es: "Establezca la nueva contraseña para su cuenta",
        ru: "Установите новый пароль для вашей учетной записи",
        en: "Set the new password for your account",
    },
    "Reset password": {
        pt: "Redefinir senha",
        es: "Restablecer contraseña",
        ru: "Сбросить пароль",
        en: "Reset password",
    },
    "Enter the code you received in your email": {
        pt: "Digite o código que você recebeu em seu email",
        es: "Ingrese el código que recibió en su email",
        ru: "Введите код, который вы получили на свой email",
        en: "Enter the code you received in your email",
    },
    "Email is not valid": {
        pt: "Email não é válido",
        es: "El email no es válido",
        ru: "Email не действителен",
        en: "Email is not valid",
    },
    "Sending email...": {
        pt: "Enviando email...",
        es: "Enviando email...",
        ru: "Отправка email...",
        en: "Sending email...",
    },
    "User is not found": {
        pt: "Usuário não encontrado",
        es: "Usuario no encontrado",
        ru: "Пользователь не найден",
        en: "User is not found",
    },
    "Something went wrong. Please try again": {
        pt: "Algo deu errado. Por favor, tente novamente",
        es: "Algo salió mal. Por favor, inténtalo de nuevo",
        ru: "Что-то пошло не так. Пожалуйста, попробуйте снова",
        en: "Something went wrong. Please try again",
    },
    "The password cannot be empty": {
        pt: "A senha não pode estar vazia",
        es: "La contraseña no puede estar vacía",
        ru: "Пароль не может быть пустым",
        en: "The password cannot be empty",
    },
    "The password is reset successfully": {
        pt: "A senha foi redefinida com sucesso",
        es: "La contraseña se restableció correctamente",
        ru: "Пароль успешно сброшен",
        en: "The password is reset successfully",
    },
    "Try again": {
        pt: "Tentar novamente",
        es: "Reintentar",
        ru: "Повторить",
        en: "Try again",
    },
    Code: {
        pt: "Código",
        es: "Código",
        ru: "Код",
        en: "Code",
    },
    "Send code": {
        pt: "Enviar código",
        es: "Enviar código",
        ru: "Отправить код",
        en: "Send code",
    },
    "Error occured:": {
        pt: "Ocorreu um erro:",
        es: "Error ocurrido:",
        ru: "Произошла ошибка:",
        en: "Error occured:",
    },
    Register: {
        pt: "Registrar",
        es: "Registrarse",
        ru: "Регистрация",
        en: "Register",
    },
    "Log in": {
        pt: "Entrar",
        es: "Iniciar sesión",
        ru: "Вход",
        en: "Log in",
    },
    Login: {
        pt: "Entrar",
        es: "Iniciar sesión",
        ru: "Войти",
        en: "Login",
    },
    Date: {
        pt: "Data",
        es: "Fecha",
        ru: "Дата",
        en: "Date",
    },
    City: {
        pt: "Cidade",
        es: "Ciudad",
        ru: "Город",
        en: "City",
    },
    Questions: {
        pt: "Perguntas",
        es: "Preguntas",
        ru: "Вопросы",
        en: "Questions",
    },
    Enter: {
        pt: "Entrar",
        es: "Entrar",
        ru: "Введите",
        en: "Enter",
    },
    "Enter first name": {
        pt: "Seu nome",
        es: "Su nombre",
        ru: "Ваше имя",
        en: "Your name",
    },
    "Enter birth date": {
        pt: "Data de nascimento",
        es: "Fecha de nacimiento",
        ru: "Дата рождения",
        en: "Date of birth",
    },
    "Enter birth time": {
        pt: "Hora de nascimento",
        es: "Hora de nacimiento",
        ru: "Время рождения",
        en: "Time of birth",
    },
    "Enter code": {
        pt: "Digite o código",
        es: "Ingrese el código",
        ru: "Введите код",
        en: "Enter code",
    },
    "Forgot password?": {
        pt: "Esqueceu a senha?",
        es: "¿Olvidaste la contraseña?",
        ru: "Забыли пароль?",
        en: "Forgot password?",
    },
    "Email or password is incorrect": {
        pt: "Email ou senha incorretos",
        es: "Email o contraseña incorrectos",
        ru: "Email или пароль неверны",
        en: "Email or password is incorrect",
    },
    CityOfBirthPlaceholder: {
        pt: "Cidade de nascimento (ex: São Paulo)",
        es: "Ciudad de nacimiento (ejemplo: São Paulo)",
        ru: "Город рождения (например: Омск)",
        en: "City of birth (e.g. New York)",
    },
    GenericError: {
        pt: "Algo deu errado. Por favor, tente novamente",
        es: "Algo salió mal. Por favor, inténtalo de nuevo",
        ru: "Что-то пошло не так. Пожалуйста, попробуйте снова",
        en: "Something went wrong. Please try again",
    },
    PrivacyPolicy: {
        pt: "Privacy policy",
        es: "Privacy policy",
        ru: "Публичная офферта",
        en: "Privacy policy",
    },
    loginSuccess: {
        pt: "Login bem-sucedido",
        es: "Inicio de sesión exitoso",
        ru: "Успешный вход",
        en: "Login successful",
    },
    registerSuccess: {
        pt: "Registro bem-sucedido",
        es: "Registro exitoso",
        ru: "Успешная регистрация",
        en: "Registration successful",
    },
    loginError: {
        pt: "Erro ao fazer login",
        es: "Error al iniciar sesión",
        ru: "Ошибка при входе",
        en: "Login error",
    },
    registerError: {
        pt: "Erro ao registrar",
        es: "Error al registrarse",
        ru: "Ошибка при регистрации",
        en: "Registration error",
    },
    userAlreadyExistsError: {
        pt: "Usuário já existe",
        es: "Usuario ya existe",
        ru: "Пользователь уже существует",
        en: "User already exists",
    },
    "Registration error": {
        pt: "Erro ao registrar",
        es: "Error al registrarse",
        ru: "Ошибка при регистрации",
        en: "Registration error",
    },
    registerNoAccess: {
        pt: "Não autorizado",
        es: "No autorizado",
        ru: "Нет доступа",
        en: "No access",
    },
    loginNoAccess: {
        pt: "Não autorizado",
        es: "No autorizado",
        ru: "Нет доступа",
        en: "No access",
    },
    "Save password": {
        pt: "Salvar senha",
        es: "Guardar contraseña",
        ru: "Сохранить пароль",
        en: "Save password",
    },
    "The password is saved": {
        pt: "A senha é salva",
        es: "La contraseña se guarda",
        ru: "Пароль сохранен",
        en: "The password is saved",
    },
    "Orders Status": {
        pt: "Status dos pedidos",
        es: "Estado de los pedidos",
        ru: "Статус заказов",
        en: "Orders Status",
    },
    "Enter!": {
        pt: "Entrar",
        es: "Entrar",
        ru: "Войти",
        en: "Enter",
    },
    "No orders found": {
        pt: "Nenhum pedido encontrado",
        es: "No se encontraron pedidos",
        ru: "Заказы не найдены",
        en: "No orders found",
    },
    "Invalid email": {
        pt: "Email inválido",
        es: "Email inválido",
        ru: "Неверный email",
        en: "Invalid email",
    },
    "Failed to create user": {
        pt: "Falha ao criar usuário",
        es: "Error al crear usuario",
        ru: "Не удалось создать пользователя",
        en: "Failed to create user",
    },
    "Product Name": {
        pt: "Nome do produto",
        es: "Nombre del producto",
        ru: "Наименование продукта",
        en: "Product Name",
    },
    Language: {
        pt: "Idioma",
        es: "Idioma",
        ru: "Язык",
        en: "Language",
    },
    Report: {
        pt: "Relatório",
        es: "Informe",
        ru: "Отчет",
        en: "Report",
    },
    "Report is not available": {
        pt: "Relatório não disponível",
        es: "Informe no disponible",
        ru: "Отчет недоступен",
        en: "Report is not available",
    },
    "Will be available by": {
        ru: "Будет готова",
        en: "Will be available by",
        pt: "Estará disponível em",
        es: "Estará disponible en",
    },
    Download: {
        pt: "Baixar",
        es: "Descargar",
        ru: "Скачать",
        en: "Download",
    },
    Person: {
        pt: "Pessoa",
        es: "Persona",
        ru: "Персона",
        en: "Person",
    },
    VENUS_AWAKENING: {
        ru: "Пробуждение Венеры",
        en: "Venus Awakening",
        pt: "Vênus Despertando",
        es: "Venus Despertando",
    },
    MONEY_CODE: {
        ru: "Космический Код Богатства",
        en: "Cosmic Wealth Code",
        pt: "Código Cósmico da Riqueza",
        es: "Código Cósmico de la Riqueza",
    },
    SUN_LIGHT_UP: {
        ru: "Зажги Солнце",
        en: "Light Up The Sun",
        pt: "Acenda o Sol",
        es: "Enciende el Sol",
    },
    MARS_ACTIVATION: {
        ru: "Активация Марса",
        en: "Mars Activation",
        pt: "Ativação de Marte",
        es: "Activación de Marte",
    },
    MERCURY_TALENTS: {
        ru: "Таланты Ребенка",
        en: "Child's Talents",
        pt: "Talentos da Criança",
        es: "Talentos del Niño",
    },
    SYNASTRY: {
        ru: "Синастрия",
        en: "Synastry",
        pt: "Sinastría",
        es: "Sinastría",
    },
    COMPATIBILITY: {
        ru: "Совместимость",
        en: "Compatibility",
        pt: "Compatibilidade",
        es: "Compatibilidad",
    },
    VEDIC_INSIGHTS: {
        ru: "Джйотис",
        en: "Jyotish",
        pt: "Jyotish",
        es: "Jyotish",
    },
    WEALTH: {
        ru: "Богатство",
        en: "Wealth",
        pt: "Riqueza",
        es: "Riqueza",
    },
    LOVE: {
        ru: "Любовь",
        en: "Love",
        pt: "Amor",
        es: "Amor",
    },
    DESTINY: {
        ru: "Судьба",
        en: "Destiny",
        pt: "Destino",
        es: "Destino",
    },
    CHILD: {
        ru: "Ребенок",
        en: "Child",
        pt: "Criança",
        es: "Niño",
    },
    Book: {
        ru: "Книга",
        en: "Book",
        pt: "Livro",
        es: "Libro",
    },
    "Order date": {
        ru: "Дата заказа",
        en: "Order date",
        pt: "Data do pedido",
        es: "Fecha del pedido",
    },
    "Order is being processed": {
        ru: "Заказ обрабатывается",
        en: "Order is being processed",
        pt: "Pedido em processamento",
        es: "El pedido está siendo procesado",
    },
    "Skip payment": {
        ru: "Пропустить платеж",
        en: "Skip payment",
        pt: "Saltar o pagamento",
        es: "Saltar el pago",
    },
    "Solar Return": {
        ru: "Солнечное Возвращение",
        en: "Solar Return",
        pt: "Retorno Solar",
        es: "Retorno Solar",
    },
};

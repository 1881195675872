import { z } from "zod";
import { LANGUAGE } from "./language.enum";
export const ZQuizInitialRequest = z.object({
    clientId: z.string(), // kind of localStorageId
    language: z.nativeEnum(LANGUAGE),
    bloggerId: z.string(),
    variationId: z.string().nullable().optional(),
    branch: z.string().optional(), // make it required later
});
export const ZQuizBaseRequest = ZQuizInitialRequest.extend({
    branch: z.string().optional(), // make it required later
    sessionId: z.string(),
});
export const ZQuizCustomizations = z.object({
    showWheelInOffer: z.boolean().optional(),
    showWheelInDemoMapa: z.boolean().optional(),
    astrologyType: z.enum(["western", "vedic"]).optional(),
});
export const ZQuizStartRequest = ZQuizInitialRequest.extend({
    sessionId: z.string().optional(), // sessionId is optional, because it will be passed only after payment
    customizations: ZQuizCustomizations.optional(),
    fb: z.any().optional(),
});
export const ZQuizRequestBaseMetadata = z
    .object({
    attempt: z.number().optional(),
    admin: z.boolean().optional(),
})
    .optional();
export const ZName = z.object({
    firstName: z.string(),
    lastName: z.string(),
});
export const ZBirthData = z.object({
    year: z.number().min(1900).max(2100),
    month: z.number().min(1).max(12),
    day: z.number().min(1).max(31),
    hour: z.number().min(0).max(23),
    minute: z.number().min(0).max(59),
    countryName: z.string().max(75).optional(),
    countryCode: z.string().length(2),
    cityName: z.string().max(100),
    cityNameLocalized: z.string().max(100).optional(),
    cityLocalAdminName: z.string().max(75).optional(),
    cityCountyName: z.string().max(75).optional(),
    cityRegionName: z.string().max(75).optional(),
    cityLatitude: z.number().optional(),
    cityLongitude: z.number().optional(),
});
export const ZGender = z.enum(["male", "female", "non-binary"]);

import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
  {
    path: "/:bloggerId/irene-venus-beauty/:catchAll(.*)?",
    name: "irene-venus-beauty",
    component: () =>
      import(
        "@/views/quiz/WesternAstrology/CustomBloggerVariations/IreneVenusBeautyView.vue"
      ),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/course-for-you/:catchAll(.*)?",
    name: "course-for-you",
    component: () => import("@/views/quiz/NewMindStartView.vue"),
    meta: { type: "quiz" },
  },
]

export default routes

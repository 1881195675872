import { getBaseQuizUrl } from "./index"

const Pages = {
  success: "payment-success",
  pending: "payment-pending",
  failure: "payment-failure",
}

const urlParams = new URLSearchParams(window.location.search)
const paymentId = urlParams.get("payment_id") || urlParams.get("order_id")

export const getParamPreferenceId =
  urlParams.get("preference_id") ?? urlParams.get("preference-id")

export const isPaymentScreen = () => {
  return window.location.pathname.includes("payment-")
}

export const getPaymentId: () => string | null = () => {
  return paymentId
}

export const isSuccessPage: () => boolean = () => {
  return [Pages.success, Pages.pending].find((path) =>
    window.location.pathname.includes(path),
  ) && getPaymentId()
    ? true
    : false
}

export const isAdminPaidSuccess: () => boolean = () => {
  return getPaymentId() === "1"
}

export const hidePaymentIdFromUrl: () => void = () => {
  history.replaceState({}, "", `${getBaseQuizUrl()}/payment-success`)
}

export const isFailurePage: () => boolean = () => {
  return window.location.pathname.includes(Pages.failure)
}

export const isWeirdPage: () => boolean = () => {
  if (isPaymentScreen()) {
    // /payment-success without payment_id is weird!
    // Probably user trying to share the link after payment success
    if (isSuccessPage() && !getPaymentId()) {
      return true
    }
  }
  return false
}

export const startOver = () => {
  history.replaceState({}, "", getBaseQuizUrl())
  location.reload()
}

import { z } from "zod";
import { ZPerson } from "./natal-chart.interfaces";
import { ZQuizBaseRequest, ZQuizRequestBaseMetadata } from "./quiz.interfaces";
// TODO: this whole file should be removed. Use generic NatalQuiz interfaces instead (rename it to WesternAstrologyQuiz or something)
export const ZCompatibilityDemoRequest = ZQuizBaseRequest.extend({
    person: ZPerson,
    partner: ZPerson,
    metadata: ZQuizRequestBaseMetadata,
});
export const ZPresaveCompatibilityQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string(),
    userData: z.object({
        email: z.string().email(),
        phone: z.string().optional(),
        person: ZPerson,
        partner: ZPerson,
        demoInterpretation: z.string().optional(),
    }),
});
export const ZSubmitCompatibilityQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string().optional(), // it's optional only for skipping the payment process for admins
    email: z.string().email(),
    potentialUserData: z.object({
        person: ZPerson,
        partner: ZPerson,
        demoInterpretation: z.string().optional(),
        answers: z.any(),
    }),
    metadata: ZQuizRequestBaseMetadata,
});

import type { RouteRecordRaw } from "vue-router"

import SmallSpinner from "@/components/SmallSpinner.vue"

const routes: RouteRecordRaw[] = [
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("@/views/ReportRecoveryView.vue"),
  },
  {
    path: "/user-insights",
    name: "user-insights",
    component: () => import("@/views/UserInsightsView.vue"),
  },
  {
    path: "/proxy/:catchAll(.*)?",
    name: "proxy",
    component: SmallSpinner,
    props: { class: "mx-auto" },
  },
  {
    path: "/system-info",
    name: "system-info",
    component: () => import("@/views/system-info/SystemInfoView.vue"),
    meta: { type: "system-info" },
  },
  {
    path: "/dto",
    name: "dto",
    component: () => import("@/views/system-info/DtoExampleView.vue"),
    meta: { type: "dto" },
  },
]

export default routes

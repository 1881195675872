import { LANGUAGE } from "@luaroxa/contracts"
import { translate as translateWithLang } from "@luaroxa/contracts"
import { isLocal } from "./dev"

export function getLanguage(): LANGUAGE {
  if (window.location.host.includes("ru.")) {
    return LANGUAGE.RU
  }
  if (window.location.host.includes("en.")) {
    return LANGUAGE.EN
  }
  if (window.location.host.includes("es.")) {
    return LANGUAGE.ES
  }
  if (window.location.host.includes(".br")) {
    return LANGUAGE.PT
  }
  return isLocal() ? LANGUAGE.RU : LANGUAGE.RU
}

export const LANG = getLanguage()

export function translate (translateKey: string, useKeyAsFallback = false): string | undefined {
  return translateWithLang(translateKey, LANG) || (useKeyAsFallback ? translateKey : undefined)
}


export const updateBodyClasses = (path = window.location.pathname) => {
  try {
    const pathSegments = path.split("/").filter(Boolean)
    const classes = pathSegments.map(
      (segment, index) => `page-${pathSegments.slice(0, index + 1).join("-")}`,
    )
    document.body.className = [...classes].join(" ")
  } catch (e) {
    console.error(e)
  }
}


export function renderPrice(price: number, _currency: string, paymentSystem?: string) {
  // RUB => ₽, USD => $, EUR => €
  const symbols: Record<string, string> = {
    RUB: "₽",
    BRL: "R$",
    USD: "$",
    EUR: "€",
  }
  const currency = _currency.toUpperCase() as keyof typeof symbols
  return `${symbols[currency] ?? currency} ${price}`
}

export function priceNumber(price: number, paymentSystem?: string) {
  return price
}


export function currencySymbol(currency: string) {
  const symbols = {
    RUB: "₽",
    BRL: "R$",
    USD: "$",
    EUR: "€",
  }

  const curr = currency.toUpperCase() as keyof typeof symbols
  return symbols[curr] ?? currency
}

export function getUtmParameters(): string | null {
  const utm = Object.fromEntries(
    Array.from(new URLSearchParams(window.location.search))
      .filter(([key]) => key.startsWith('utm_'))
  );
  if (Object.keys(utm).length === 0) return null;
  
  return Object.entries(utm)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}


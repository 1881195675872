import { z } from "zod";
import { ZPerson } from "./natal-chart.interfaces";
import { ZQuizBaseRequest, ZQuizRequestBaseMetadata } from "./quiz.interfaces";
import { ZLegacyChart } from "./WesternAstrology/western-astrology.chart.interfaces";
export const ZPresaveNatalQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string(),
    userData: z.object({
        person: ZPerson,
        partner: ZPerson.optional(),
        email: z.string().email(),
        phone: z.string().optional(),
        demoInterpretation: z.string().optional(),
        answers: z.any(),
        solarYear: z.number().optional(),
    }),
});
export const ZSubmitNatalQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string().optional(), // it's optional only for skipping the payment process for admins
    email: z.string().email(),
    potentialUserData: z.object({
        person: ZPerson,
        partner: ZPerson.optional(),
        demoInterpretation: z.string().optional(),
        answers: z.any(),
        solarYear: z.number().optional(),
    }),
    metadata: ZQuizRequestBaseMetadata,
});
export const ZNatalDemoRequest = ZQuizBaseRequest.extend({
    natalChart: ZLegacyChart.optional(), // should remove?
    person: ZPerson,
    partner: ZPerson.optional(),
    metadata: ZQuizRequestBaseMetadata,
});

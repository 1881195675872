import { z } from "zod";
import { ZObjectIdLike } from "./generic.interfaces";
import { LANGUAGE } from "./language.enum";
import { ZPerson } from "./natal-chart.interfaces";
export const ZUserBirthData = z.object({
    year: z.number().min(1900).max(2100),
    month: z.number().min(1).max(12),
    day: z.number().min(1).max(31),
    hour: z.number().min(0).max(23),
    minute: z.number().min(0).max(59),
    countryName: z.string().max(75),
    countryCode: z.string().length(2),
    cityName: z.string().max(75),
    cityLocalAdminName: z.string().max(75).optional(),
    cityCountyName: z.string().max(75).optional(),
    cityRegionName: z.string().max(75).optional(),
    cityLatitude: z.number(),
    cityLongitude: z.number(),
});
export const ZUser = z.object({
    _id: ZObjectIdLike,
    person: ZPerson,
    partner: ZPerson,
    email: z.string().email(),
    password: z.string().optional(),
    createdAt: z.string().transform((val) => new Date(val)),
    updatedAt: z.string().transform((val) => new Date(val)),
    clientId: z.string(),
    answers: z.record(z.string(), z.string()).optional(),
    attachments: z.any().optional(),
    language: z.nativeEnum(LANGUAGE),
    telegramId: z.number().optional(),
    phone: z.string().optional(),
    bloggerId: z.string().optional(),
});
export const ZUpdateProfileRequest = z.object({
    email: z.string().email().optional(),
    password: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    answers: z.record(z.string(), z.string()).optional(),
});
export const ZCompleteOnboardingRequest = z.object({
    person: ZPerson.optional(),
    answers: z.record(z.string(), z.string()).optional(),
});

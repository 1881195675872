<template>
  <div class="spinner"></div>
</template>

<style scoped lang="postcss">
.spinner {
  border: 3px solid rgb(var(--color-primary-500) / 0.2);
  border-top: 3px solid rgb(var(--color-primary-500));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
  // {
  //   path: "",
  //   redirect: "/lua/natal-chart",
  // },
  {
    path: "/:bloggerId/mapa-natal/:catchAll(.*)?",
    name: "mapa-natal",
    component: () => import("@/views/quiz/WesternAstrology/MapaNatalView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/natal-chart/:catchAll(.*)?",
    name: "natal-chart",
    component: () => import("@/views/quiz/WesternAstrology/NatalChartView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/vedic-insights/:catchAll(.*)?",
    name: "vedic-insights",
    component: () =>
      import("@/views/quiz/VedicAstrology/VedicInsightsView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/forecast-2024/:catchAll(.*)?",
    name: "forecast-2024",
    component: () =>
      import("@/views/quiz/WesternAstrology/Forecast2024View.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/compatibility-mini/:catchAll(.*)?",
    name: "compatibility-mini",
    component: () =>
      import("@/views/quiz/WesternAstrology/CompatibilityMiniView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/compatibility/:catchAll(.*)?",
    name: "compatibility",
    component: () =>
      import("@/views/quiz/WesternAstrology/CompatibilityView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/daily-offer/:catchAll(.*)?",
    name: "daily-offer",
    component: () =>
      import("@/views/quiz/WesternAstrology/DailyReportView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/venus-awakening/:catchAll(.*)?",
    name: "venus-awakening",
    component: () =>
      import("@/views/quiz/WesternAstrology/VenusAwakeningView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/money-code/:catchAll(.*)?",
    name: "money-code",
    component: () => import("@/views/quiz/WesternAstrology/MoneyCodeView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/sun-light-up/:catchAll(.*)?",
    name: "sun-light-up",
    component: () => import("@/views/quiz/WesternAstrology/SunLightUpView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/mars-activation/:catchAll(.*)?",
    name: "mars-activation",
    component: () =>
      import("@/views/quiz/WesternAstrology/MarsActivationView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/mercury-talents/:catchAll(.*)?",
    name: "mercury-talents",
    component: () =>
      import("@/views/quiz/WesternAstrology/MercuryTalentsView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/synastry/:catchAll(.*)?",
    name: "synastry",
    component: () => import("@/views/quiz/WesternAstrology/SynastryView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/superpower/:catchAll(.*)?",
    name: "superpower",
    component: () => import("@/views/quiz/WesternAstrology/SuperpowerView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/wealth/:catchAll(.*)?",
    name: "wealth",
    component: () => import("@/views/quiz/WesternAstrology/WealthView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/love/:catchAll(.*)?",
    name: "love",
    component: () => import("@/views/quiz/WesternAstrology/LoveView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/destiny/:catchAll(.*)?",
    name: "destiny",
    component: () => import("@/views/quiz/WesternAstrology/DestinyView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/child/:catchAll(.*)?",
    name: "child",
    component: () => import("@/views/quiz/WesternAstrology/ChildView.vue"),
    meta: { type: "quiz" },
  },
  {
    path: "/:bloggerId/solar-return/:catchAll(.*)?",
    name: "solar-return",
    component: () =>
      import("@/views/quiz/WesternAstrology/SolarReturnView.vue"),
    meta: { type: "quiz" },
  },

  {
    path: "/:bloggerId/order-status/:orderId?",
    name: "order-status",
    component: () => import("@/order-status/OrderStatusView.vue"),
  },
]

export default routes

import { z } from "zod";
const ZVedicPlanetName = z.union([
    z.literal("Sun"),
    z.literal("Moon"),
    z.literal("Mercury"),
    z.literal("Venus"),
    z.literal("Mars"),
    z.literal("Jupiter"),
    z.literal("Saturn"),
    z.literal("Rahu"), // North Node, considered a shadow planet
    z.literal("Ketu"), // South Node, also a shadow planet
]);
const ZVedicPlanetKey = z.union([
    z.literal("sun"),
    z.literal("moon"),
    z.literal("mercury"),
    z.literal("venus"),
    z.literal("mars"),
    z.literal("jupiter"),
    z.literal("saturn"),
    z.literal("rahu"),
    z.literal("ketu"),
]);
const ZVedicPointName = z.union([
    z.literal("Lagna"), // Ascendant
    z.literal("Amatya Karaka"), // Indicator of Career/Profession
    z.literal("Atma Karaka"), // Indicator of the Soul
    z.literal("Arudha Lagna"), // Indicative of how others perceive you
    z.literal("Upapada Lagna"), // Related to relationships and marriage
    z.literal("Yoga Point"), // Specific points derived from certain planetary combinations
]);
const ZVedicPointKey = z.union([
    z.literal("lagna"), // Ascendant
    z.literal("amatyaKaraka"), // Indicator of Career/Profession
    z.literal("atmaKaraka"), // Indicator of the Soul
    z.literal("arudhaLagna"), // Indicative of how others perceive you
    z.literal("upapadaLagna"), // Related to relationships and marriage
    z.literal("yogaPoint"), // Specific points derived from certain planetary combinations
]);
const ZVedicSignName = z.union([
    z.literal("Mesha"), // Aries
    z.literal("Vrishabha"), // Taurus
    z.literal("Mithuna"), // Gemini
    z.literal("Karka"), // Cancer
    z.literal("Simha"), // Leo
    z.literal("Kanya"), // Virgo
    z.literal("Tula"), // Libra
    z.literal("Vrishchika"), // Scorpio
    z.literal("Dhanu"), // Sagittarius
    z.literal("Makara"), // Capricorn
    z.literal("Kumbha"), // Aquarius
    z.literal("Meena"), // Pisces
]);
const ZVedicHouseName = z.enum([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
]);
const ZVedicPlanet = z.object({
    name: ZVedicPlanetName,
    sign: ZVedicSignName,
    house: z.number().min(1).max(12),
    longitude: z.number(),
    degrees: z.number(),
    speed: z.number(),
    retrograde: z.boolean(),
});
const ZVedicPoint = z.object({
    name: ZVedicPointName,
    sign: ZVedicSignName,
    house: z.number().min(1).max(12).optional(),
    longitude: z.number().optional(),
    degrees: z.number(),
    speed: z.number().optional(),
    retrograde: z.boolean().optional(),
});
const ZVedicHouse = z.object({
    house: z.number().min(1).max(12),
    planets: z.array(ZVedicPlanetName),
    sign: ZVedicSignName,
    ruler: z.string(),
    longitude: z.number(),
    degrees: z.number(),
});
const ZVedicAspectType = z.union([
    z.literal("full"), // Full aspect (7th house opposite), applies universally
    z.literal("conjunction"), // When planets are in the same sign
    z.literal("opposition"), // Direct opposition, similar to Western
    z.literal("drishti"), // Specific Vedic aspect, generally means 'sight' and refers to special aspectual views
    z.literal("special_aspect"), // Covers specific aspects of Mars (4th, 7th, 8th), Jupiter (5th, 7th, 9th), and Saturn (3rd, 7th, 10th)
    z.string(), // For additional custom aspect types or less commonly used aspects
]);
const ZVedicAspect = z.object({
    planet1: z.string(),
    planet2: z.string(),
    type: ZVedicAspectType,
    longitude: z.number(),
});
export const ZVedicChart = z.object({
    planets: z.record(ZVedicPlanetKey, ZVedicPlanet),
    points: z.record(ZVedicPointKey, ZVedicPoint).optional(),
    houses: z.record(ZVedicHouseName, ZVedicHouse),
    ascendant: z.object({
        sign: ZVedicSignName,
    }),
    aspects: z.array(ZVedicAspect),
    wheel: z.string().optional(),
});
export const ZVedicChartTexts = z.object({
    full: z.string().optional(),
    planets: z.string().optional(),
    aspects: z.string().optional(),
    cusps: z.string().optional(),
    birthData: z.string().optional(),
});

import type { IPerson } from "@luaroxa/contracts"
import { watchDeep } from "@vueuse/core"
import { defineStore } from "pinia"

import type { IPlaceAutocompleteResponse } from "@/components/PlaceAutocomplete.vue"
import { deepMerge } from "@/helpers"

type Inputs = {
  person: IPerson
  partner: IPerson
  email: string
  natalDemoFeedback: string
  natalPdfFeedback: string
  compatibilityDemoFeedback: string
  compatibilityPdfFeedback: string
  answers: Record<string, string>
  phone: string
  solarYear?: number
}

const defaultPerson = {
  firstName: "",
  lastName: "",
  birthData: {
    year: null,
    month: null,
    day: null,
    hour: null,
    minute: null,
    countryName: null,
    countryCode: null,
    cityName: null,
    cityNameLocalized: null,
    cityLocalAdminName: null,
    cityCountyName: null,
    cityRegionName: null,
    cityLatitude: null,
    cityLongitude: null,
  },
}

const defaults: Inputs = {
  person: { ...structuredClone(defaultPerson) },
  partner: { ...structuredClone(defaultPerson) },
  email: "",
  natalDemoFeedback: "",
  natalPdfFeedback: "",
  compatibilityDemoFeedback: "",
  compatibilityPdfFeedback: "",
  answers: {},
  phone: "",
}

const localStorageKey = "pinia/inputs/2"

const fetchSavedData = (): Inputs | null => {
  const rawData = localStorage.getItem(localStorageKey)
  if (!rawData) return null

  try {
    return JSON.parse(rawData) as Inputs
  } catch (error) {
    console.error("Failed to parse saved data:", error)
    return null
  }
}

export const useInputsStore = defineStore("inputs", () => {
  const savedData = fetchSavedData() || {}
  const inputs = reactive<Inputs>(deepMerge(defaults, savedData))

  watchDeep(inputs, (update) => {
    localStorage.setItem(localStorageKey, JSON.stringify(update))
  })

  const setPlace = (
    place: IPlaceAutocompleteResponse,
    isSecondPerson = false,
  ) => {
    const target = isSecondPerson ? inputs.partner : inputs.person
    Object.assign(target.birthData, {
      cityName: place.asciName,
      cityNameLocalized: place.name,
      cityLocalAdminName: place.localadmin,
      cityCountyName: place.county,
      cityRegionName: place.region,
      countryName: place.country,
      countryCode: place.country_code,
      cityLatitude: place.latitude,
      cityLongitude: place.longitude,
    })
  }

  return {
    inputs,
    setPlace,
  }
})

import { useMutation } from "@tanstack/vue-query"

import { createQuizApiClient, type QuizApiClient } from "@/api/quiz-api"

let quizClient: QuizApiClient | null = null
const apiUrl = import.meta.env.VITE_API_URL

export function useInitializeQuizApi() {
  if (!apiUrl) {
    throw new Error("apiUrl is not defined")
  }

  return useMutation<QuizApiClient>({
    mutationFn: async () => {
      quizClient = createQuizApiClient({
        apiUrl,
      })
      return quizClient
    },
  })
}

export function useQuizApi() {
  if (quizClient == null) {
    quizClient = createQuizApiClient({
      apiUrl,
    })
  }

  return quizClient
}

import {
  PaymentPriceType,
  PaymentSystem,
  type ProductType,
} from "@luaroxa/contracts"
import { defineStore } from "pinia"

export interface PaymentBrick {
  preferenceId: string
  redirectUrl?: string
  paymentSystem?: PaymentSystem
}

export interface IPaymentState {
  price: number
  priceDownsale?: number
  stripePriceId?: string
  stripePriceDownsaleId?: string
  bricks: {
    [PaymentPriceType.NORMAL]: PaymentBrick[]
    [PaymentPriceType.DOWNSALE]: PaymentBrick[]
  }
  activeBrick: PaymentPriceType
  "price/2": number
  "price_downsale/2"?: number
  currency: string
  receivedPreferenceId?: string
  gifts: ProductType[]
  shouldSkip: boolean
  activePreferenceId: string
}

const defaults: IPaymentState = {
  price: 0,
  priceDownsale: undefined,
  bricks: {
    [PaymentPriceType.NORMAL]: [],
    [PaymentPriceType.DOWNSALE]: [],
  },
  activeBrick: PaymentPriceType.NORMAL,
  "price/2": 0,
  "price_downsale/2": 0,
  currency: "",
  receivedPreferenceId: undefined,
  gifts: [],
  shouldSkip: false,
  activePreferenceId: "",
}

export const usePaymentStore = defineStore("payment", () => {
  const state = reactive<IPaymentState>(defaults)

  const setPrice = (price: number) => {
    state.price = price
    state["price/2"] = price / 2
  }

  const setPriceDownsale = (price?: number) => {
    state.priceDownsale = price
    state["price_downsale/2"] = price / 2
  }

  const setCurrency = (currency: string) => {
    state.currency = currency
  }

  const setGifts = (gifts: ProductType[]) => {
    state.gifts = gifts
  }

  const setShouldSkip = (value: boolean) => {
    state.shouldSkip = value
  }

  const setActivePreferenceId = (preferenceId: string) => {
    state.activePreferenceId = preferenceId
  }

  return {
    paymentState: state,
    activePreferenceId: computed(() => state.activePreferenceId),
    isInitialized: computed(() => state.bricks[state.activeBrick].length > 0),
    paymentBricks: computed(() => state.bricks[state.activeBrick]),

    bootstrapBrick: (priceType: PaymentPriceType, paymentBrick: PaymentBrick) =>
      state.bricks[priceType].push(paymentBrick),
    setActiveBrick: (priceType: PaymentPriceType) =>
      (state.activeBrick = priceType),
    hasDownsale: computed(() => !!state.priceDownsale),
    setPrice,
    setPriceDownsale,
    setStripePriceId: (priceId: string) => (state.stripePriceId = priceId),
    setStripePriceDownsaleId: (priceId: string) =>
      (state.stripePriceDownsaleId = priceId),
    setCurrency,
    currency: computed(() => state.currency),
    setReceivedPreferenceId: (preferenceId: string) =>
      (state.receivedPreferenceId = preferenceId),
    payToEmailRequest: () => {
      useAmplitude()?.logQuizPaymentClick()
      useScreens()?.nextStep()
    },
    setGifts,
    setShouldSkip,
    setActivePreferenceId,
  }
})

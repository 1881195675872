import "./assets/main.css"
import "./polyfills"
import "whatwg-fetch"

import VueCountdown from "@chenfengyuan/vue-countdown"
import { createGtm } from "@gtm-support/vue-gtm"
import * as Sentry from "@sentry/vue"
import { VueQueryPlugin } from "@tanstack/vue-query"
import * as amplitude from '@amplitude/analytics-browser';
import { createPinia } from "pinia"
import { ConfigProvider } from "vant"
import { createApp } from "vue"
import VueGtag from "vue-gtag-next"

import App from "./App.vue"
import router from "./router"
if (import.meta.env.PROD) {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(VueQueryPlugin)
app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_ID,
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: import.meta.env.PROD,
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    // vueRouter: router,
    ignoredViews: [], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
)
app.use(VueGtag, {
  isEnabled: false, // we enable it later after we got clientId
  property: {
    id: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  },
})

app.use(ConfigProvider)
app.component(VueCountdown.name, VueCountdown)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://e90a3a65e46f46f7ade8103bbd038c67@o4505521586438144.ingest.sentry.io/4505521589125120",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.PROD ? 0.01 : 0.1, // 1.0 = Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: import.meta.env.PROD ? 0 : 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      try {
        const { inputs } = useInputsStore()
        const { paymentState: payment } = usePaymentStore()
        const { flowState: flow } = useFlowStore()

        if (!event.user) {
          event.user = {}
        }

        event.user.store = {
          inputs,
          payment,
          flow,
        }

        console.warn("Sentry beforeSend", event.user)
        return event
      } catch (e) {
        console.error(e)
        return event
      }
    },
  })
}
app.mount("#app")

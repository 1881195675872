import { z } from "zod";
export const AMPLITUDE_EVENTS = {
    QUIZ: "quiz",
    QUIZ_STARTED: "quiz_started",
    QUIZ_GENDER: "quiz_gender",
    QUIZ_BITHDAY_OK: "quiz_bithday_ok",
    QUIZ_BITHTIME_OK: "quiz_bithtime_ok",
    QUIZ_BITHCITY_OK: "quiz_bithcity_ok",
    QUIZ_WARMUP_QUESTIONS: "quiz_warmup_questions",
    QUIZ_TELEGRAM_OFFER: "quiz_telegram_offer",
    QUIZ_MAP_MADE: "quiz_map_made",
    QUIZ_PROMPT: "quiz_prompt",
    QUIZ_MAPA_DESCRIPTION: "quiz_mapa_description",
    QUIZ_MAPA_SLIDER: "quiz_mapa_slider",
    QUIZ_OFFER_MADE: "quiz_offer_made",
    QUIZ_EMAIL: "quiz_email",
    QUIZ_MAP_OPEN: "quiz_map_open",
    QUIZ_PAYMENT_CLICK: "quiz_payment_click",
    QUIZ_PAID: "quiz_paid",
    QUIZ_PAYMENT_SUCCESS: "quiz_payment_success",
    SET_PASSWORD: "set_password",
    ENTER_ACCOUNT: "enter_account",
    CLICK_REPORT_MAPA_NATAL: "click_report_mapa_natal",
    CLICK_REPORT_COMPATIBILITY: "click_report_compatibility",
    CLICK_REPORT_COMPATIBILITY_MINI: "click_report_compatibility_mini",
    CLICK_REPORT_FORECAST_2024: "click_report_2024",
    REDIRECT: "redirect",
};
export const AmplitudeEventSchema = z.enum([
    AMPLITUDE_EVENTS.QUIZ,
    AMPLITUDE_EVENTS.QUIZ_STARTED,
    AMPLITUDE_EVENTS.QUIZ_GENDER,
    AMPLITUDE_EVENTS.QUIZ_BITHDAY_OK,
    AMPLITUDE_EVENTS.QUIZ_BITHTIME_OK,
    AMPLITUDE_EVENTS.QUIZ_BITHCITY_OK,
    AMPLITUDE_EVENTS.QUIZ_WARMUP_QUESTIONS,
    AMPLITUDE_EVENTS.QUIZ_TELEGRAM_OFFER,
    AMPLITUDE_EVENTS.QUIZ_MAP_MADE,
    AMPLITUDE_EVENTS.QUIZ_PROMPT,
    AMPLITUDE_EVENTS.QUIZ_MAPA_DESCRIPTION,
    AMPLITUDE_EVENTS.QUIZ_MAPA_SLIDER,
    AMPLITUDE_EVENTS.QUIZ_OFFER_MADE,
    AMPLITUDE_EVENTS.QUIZ_EMAIL,
    AMPLITUDE_EVENTS.QUIZ_MAP_OPEN,
    AMPLITUDE_EVENTS.QUIZ_PAYMENT_CLICK,
    AMPLITUDE_EVENTS.QUIZ_PAID,
    AMPLITUDE_EVENTS.QUIZ_PAYMENT_SUCCESS,
    AMPLITUDE_EVENTS.SET_PASSWORD,
    AMPLITUDE_EVENTS.ENTER_ACCOUNT,
    AMPLITUDE_EVENTS.CLICK_REPORT_MAPA_NATAL,
    AMPLITUDE_EVENTS.CLICK_REPORT_COMPATIBILITY,
    AMPLITUDE_EVENTS.CLICK_REPORT_COMPATIBILITY_MINI,
    AMPLITUDE_EVENTS.CLICK_REPORT_FORECAST_2024,
    AMPLITUDE_EVENTS.REDIRECT,
]);
export const AMPLITUDE_TEST_ID_PARAM = "test_id";
export const AMPLITUDE_PRODUCT_ID_PARAM = "product";
export const AMPLITUDE_PRODUCT_ID_PARAM_OLD = "Test_name"; // TODO: remove later
export const AMPLITUDE_BRANCH_PARAM = "quiz_branch";
export const AMPLITUDE_PRICE_TYPE_PARAM = "price_type";
export const AMPLITUDE_LANGUAGE_PARAM = "language";
export const AMPLITUDE_HOST_PARAM = "host";
export const AmplitudeParams = {
    test: "test_id",
    product: "product",
    branch: "quiz_branch",
    priceType: "price_type",
    language: "language",
    host: "host",
    purchaseNumber: "purchase_number",
    email: "email",
    blogger: "blogger",
    variation: "variation",
    countryCode: "country_code",
    directSale: "direct_sale",
};

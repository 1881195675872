export const chartTranslations = {
    Aries: {
        pt: "Áries",
        es: "Aries",
        ru: {
            default: "Овен",
            in: "Овне",
            with: "Овном",
        },
        en: "Aries",
    },
    Taurus: {
        pt: "Touro",
        es: "Tauro",
        ru: {
            default: "Телец",
            in: "Тельце",
            with: "Тельцом",
        },
        en: "Taurus",
    },
    Gemini: {
        pt: "Gêmeos",
        es: "Géminis",
        ru: {
            default: "Близнецы",
            in: "Близнецах",
            with: "Близнецами",
        },
        en: "Gemini",
    },
    Cancer: {
        pt: "Câncer",
        es: "Cáncer",
        ru: {
            default: "Рак",
            in: "Раке",
            with: "Раком",
        },
        en: "Cancer",
    },
    Leo: {
        pt: "Leão",
        es: "Leo",
        ru: {
            default: "Лев",
            in: "Льве",
            with: "Львом",
        },
        en: "Leo",
    },
    Virgo: {
        pt: "Virgem",
        es: "Virgo",
        ru: {
            default: "Дева",
            in: "Деве",
            with: "Девой",
        },
        en: "Virgo",
    },
    Libra: {
        pt: "Libra",
        es: "Libra",
        ru: {
            default: "Весы",
            in: "Весах",
            with: "Весами",
        },
        en: "Libra",
    },
    Scorpio: {
        pt: "Escorpião",
        es: "Escorpio",
        ru: {
            default: "Скорпион",
            in: "Скорпионе",
            with: "Скорпионом",
        },
        en: "Scorpio",
    },
    Sagittarius: {
        pt: "Sagitário",
        es: "Sagitario",
        ru: {
            default: "Стрелец",
            in: "Стрельце",
            with: "Стрельцом",
        },
        en: "Sagittarius",
    },
    Capricorn: {
        pt: "Capricórnio",
        es: "Capricornio",
        ru: {
            default: "Козерог",
            in: "Козероге",
            with: "Козерогом",
        },
        en: "Capricorn",
    },
    Aquarius: {
        pt: "Aquário",
        es: "Acuario",
        ru: {
            default: "Водолей",
            in: "Водолее",
            with: "Водолеем",
        },
        en: "Aquarius",
    },
    Pisces: {
        pt: "Peixes",
        es: "Piscis",
        ru: {
            default: "Рыбы",
            in: "Рыбах",
            with: "Рыбами",
        },
        en: "Pisces",
    },
    Sun: {
        pt: "Sol",
        es: "Sol",
        ru: {
            default: "Солнце",
            in: "Солнце",
            with: "Солнцем",
        },
        en: "Sun",
    },
    Moon: {
        pt: "Lua",
        es: "Luna",
        ru: {
            default: "Луна",
            in: "Луне",
            with: "Луной",
        },
        en: "Moon",
    },
    Mercury: {
        pt: "Mercúrio",
        es: "Mercurio",
        ru: {
            default: "Меркурий",
            in: "Меркурии",
            with: "Меркурием",
        },
        en: "Mercury",
    },
    Venus: {
        pt: "Vênus",
        es: "Venus",
        ru: {
            default: "Венера",
            in: "Венере",
            with: "Венерой",
        },
        en: "Venus",
    },
    Mars: {
        pt: "Marte",
        es: "Marte",
        ru: {
            default: "Марс",
            in: "Марсе",
            with: "Марсом",
        },
        en: "Mars",
    },
    Jupiter: {
        pt: "Júpiter",
        es: "Júpiter",
        ru: {
            default: "Юпитер",
            in: "Юпитере",
            with: "Юпитером",
        },
        en: "Jupiter",
    },
    Saturn: {
        pt: "Saturno",
        es: "Saturno",
        ru: {
            default: "Сатурн",
            in: "Сатурне",
            with: "Сатурном",
        },
        en: "Saturn",
    },
    Uranus: {
        pt: "Urano",
        es: "Urano",
        ru: {
            default: "Уран",
            in: "Уране",
            with: "Ураном",
        },
        en: "Uranus",
    },
    Neptune: {
        pt: "Netuno",
        es: "Neptuno",
        ru: {
            default: "Нептун",
            in: "Нептуне",
            with: "Нептуном",
        },
        en: "Neptune",
    },
    Rahu: {
        pt: "Rahu",
        es: "Rahu",
        ru: {
            default: "Раху",
            in: "Раху",
            with: "Раху",
        },
        en: "Rahu",
    },
    Ketu: {
        pt: "Ketu",
        es: "Ketu",
        ru: {
            default: "Кету",
            in: "Кету",
            with: "Кету",
        },
        en: "Ketu",
    },
    Ascendant: {
        pt: "Ascendente",
        es: "Ascendente",
        ru: {
            default: "Асцендент",
            in: "Асценденте",
            with: "Асцендентом",
        },
        en: "Ascendant",
    },
    Natal: {
        pt: "Natal",
        es: "Natal",
        ru: {
            default: "Натальная",
            in: "Натальной",
            with: "Натальной",
        },
        en: "Natal",
    },
    Lagna: {
        pt: "Lagna",
        es: "Lagna",
        ru: {
            default: "Лагна",
            in: "Лагне",
            with: "Лагной",
        },
        en: "Lagna",
    },
    Pluto: {
        pt: "Plutão",
        es: "Plutón",
        ru: {
            default: "Плутон",
            in: "Плутоне",
            with: "Плутоном",
        },
        en: "Pluto",
    },
    Chiron: {
        pt: "Quíron",
        es: "Quirón",
        ru: {
            default: "Хирон",
            in: "Хироне",
            with: "Хироном",
        },
        en: "Chiron",
    },
    "North Node": {
        pt: "Nodo Norte",
        es: "Nodo Norte",
        ru: {
            default: "Северный узел",
            in: "Северном узле",
            with: "Северным узлом",
        },
        en: "North Node",
    },
    "South Node": {
        pt: "Nodo Sul",
        es: "Nodo Sur",
        ru: {
            default: "Южный узел",
            in: "Южном узле",
            with: "Южным узлом",
        },
        en: "South Node",
    },
    "North Node (Mean)": {
        pt: "Nodo Norte (Médio)",
        es: "Nodo Norte (Medio)",
        ru: {
            default: "Северный узел (Средний)",
            in: "Северном узле (Средний)",
            with: "Северным узлом (Средний)",
        },
        en: "North Node (Mean)",
    },
    Node: {
        pt: "Nodo Norte",
        es: "Nodo Norte",
        ru: {
            default: "Северный узел",
            in: "Северном узле",
            with: "Северным узлом",
        },
        en: "North Node",
    },
    Fortune: {
        pt: "Fortuna",
        es: "Fortuna",
        ru: {
            default: "Фортуна",
            in: "Фортуне",
            with: "Фортуной",
        },
        en: "Fortune",
    },
    "Pars Fortuna": {
        pt: "Fortuna",
        es: "Fortuna",
        ru: {
            default: "Фортуна",
            in: "Фортуне",
            with: "Фортуной",
        },
        en: "Fortune",
    },
    "Part of Fortune": {
        pt: "Parte da Fortuna",
        es: "Parte de la Fortuna",
        ru: {
            default: "Фортуна",
            in: "Фортуне",
            with: "Фортуной",
        },
        en: "Fortune",
    },
    Lilith: {
        pt: "Lilith",
        es: "Lilith",
        ru: {
            default: "Лилит",
            in: "Лилите",
            with: "Лилитом",
        },
        en: "Lilith",
    },
    Vesta: {
        pt: "Vesta",
        es: "Vesta",
        ru: {
            default: "Веста",
            in: "Весте",
            with: "Вестой",
        },
        en: "Vesta",
    },
    trine: {
        pt: "trígono",
        es: "trino",
        ru: {
            default: "тригон",
            in: "тригоне",
            with: "тригоном",
        },
        en: "trine",
    },
    sextile: {
        pt: "sextil",
        es: "sextil",
        ru: {
            default: "секстиль",
            in: "секстиле",
            with: "секстилем",
        },
        en: "sextile",
    },
    conjunction: {
        pt: "conjunção",
        es: "conjunción",
        ru: {
            default: "соединение",
            in: "соединении",
            with: "соединением",
        },
        en: "conjunction",
    },
    opposition: {
        pt: "oposição",
        es: "oposición",
        ru: {
            default: "оппозиция",
            in: "оппозиции",
            with: "оппозицией",
        },
        en: "opposition",
    },
    opposite: {
        pt: "oposto",
        es: "opuesto",
        ru: {
            default: "оппозиция",
            in: "оппозиции",
            with: "оппозицией",
        },
        en: "opposite",
    },
    square: {
        pt: "quadratura",
        es: "cuadratura",
        ru: {
            default: "квадрат",
            in: "квадрате",
            with: "квадратом",
        },
        en: "square",
    },
    inconjunct: {
        pt: "inconjunto",
        es: "inconjunto",
        ru: {
            default: "несоединение",
            in: "несоединении",
            with: "несоединением",
        },
        en: "inconjunct",
    },
    semisquare: {
        pt: "semiquadratura",
        es: "semicuadratura",
        ru: {
            default: "полуквадрат",
            in: "полуквадрате",
            with: "полуквадратом",
        },
        en: "semisquare",
    },
    Овен: {
        pt: "Áries",
        es: "Aries",
        ru: {
            default: "Овен",
            in: "Овне",
            with: "Овном",
        },
        en: "Aries",
    },
    Телец: {
        pt: "Touro",
        es: "Tauro",
        ru: {
            default: "Телец",
            in: "Тельце",
            with: "Тельцом",
        },
        en: "Taurus",
    },
    Близнецы: {
        pt: "Gêmeos",
        es: "Géminis",
        ru: {
            default: "Близнецы",
            in: "Близнецах",
            with: "Близнецами",
        },
        en: "Gemini",
    },
    Рак: {
        pt: "Câncer",
        es: "Cáncer",
        ru: {
            default: "Рак",
            in: "Раке",
            with: "Раком",
        },
        en: "Cancer",
    },
    Лев: {
        pt: "Leão",
        es: "Leo",
        ru: {
            default: "Лев",
            in: "Льве",
            with: "Львом",
        },
        en: "Leo",
    },
    Дева: {
        pt: "Virgem",
        es: "Virgo",
        ru: {
            default: "Дева",
            in: "Деве",
            with: "Девой",
        },
        en: "Virgo",
    },
    Весы: {
        pt: "Libra",
        es: "Libra",
        ru: {
            default: "Весы",
            in: "Весах",
            with: "Весами",
        },
        en: "Libra",
    },
    Скорпион: {
        pt: "Escorpião",
        es: "Escorpio",
        ru: {
            default: "Скорпион",
            in: "Скорпионе",
            with: "Скорпионом",
        },
        en: "Scorpio",
    },
    Стрелец: {
        pt: "Sagitário",
        es: "Sagitario",
        ru: {
            default: "Стрелец",
            in: "Стрельце",
            with: "Стрельцом",
        },
        en: "Sagittarius",
    },
    Козерог: {
        pt: "Capricórnio",
        es: "Capricornio",
        ru: {
            default: "Козерог",
            in: "Козероге",
            with: "Козерогом",
        },
        en: "Capricorn",
    },
    Водолей: {
        pt: "Aquário",
        es: "Acuario",
        ru: {
            default: "Водолей",
            in: "Водолее",
            with: "Водолеем",
        },
        en: "Aquarius",
    },
    Рыбы: {
        pt: "Peixes",
        es: "Piscis",
        ru: {
            default: "Рыбы",
            in: "Рыбах",
            with: "Рыбами",
        },
        en: "Pisces",
    },
    Солнце: {
        pt: "Sol",
        es: "Sol",
        ru: {
            default: "Солнце",
            in: "Солнце",
            with: "Солнцем",
        },
        en: "Sun",
    },
    Луна: {
        pt: "Lua",
        es: "Luna",
        ru: {
            default: "Луна",
            in: "Луне",
            with: "Луной",
        },
        en: "Moon",
    },
    Меркурий: {
        pt: "Mercúrio",
        es: "Mercurio",
        ru: {
            default: "Меркурий",
            in: "Меркурии",
            with: "Меркурием",
        },
        en: "Mercury",
    },
    Венера: {
        pt: "Vênus",
        es: "Venus",
        ru: {
            default: "Венера",
            in: "Венере",
            with: "Венерой",
        },
        en: "Venus",
    },
    Марс: {
        pt: "Marte",
        es: "Marte",
        ru: {
            default: "Марс",
            in: "Марсе",
            with: "Марсом",
        },
        en: "Mars",
    },
    Юпитер: {
        pt: "Júpiter",
        es: "Júpiter",
        ru: {
            default: "Юпитер",
            in: "Юпитере",
            with: "Юпитером",
        },
        en: "Jupiter",
    },
    Сатурн: {
        pt: "Saturno",
        es: "Saturno",
        ru: {
            default: "Сатурн",
            in: "Сатурне",
            with: "Сатурном",
        },
        en: "Saturn",
    },
    Уран: {
        pt: "Urano",
        es: "Urano",
        ru: {
            default: "Уран",
            in: "Уране",
            with: "Ураном",
        },
        en: "Uranus",
    },
    Нептун: {
        pt: "Netuno",
        es: "Neptuno",
        ru: {
            default: "Нептун",
            in: "Нептуне",
            with: "Нептуном",
        },
        en: "Neptune",
    },
    Раху: {
        pt: "Rahu",
        es: "Rahu",
        ru: {
            default: "Раху",
            in: "Раху",
            with: "Раху",
        },
        en: "Rahu",
    },
    Кету: {
        pt: "Ketu",
        es: "Ketu",
        ru: {
            default: "Кету",
            in: "Кету",
            with: "Кету",
        },
        en: "Ketu",
    },
    Асцендент: {
        pt: "Ascendente",
        es: "Ascendente",
        ru: {
            default: "Асцендент",
            in: "Асценденте",
            with: "Асцендентом",
        },
        en: "Ascendant",
    },
    Натальная: {
        pt: "Natal",
        es: "Natal",
        ru: {
            default: "Натальная",
            in: "Натальной",
            with: "Натальной",
        },
        en: "Natal",
    },
    Лагна: {
        pt: "Lagna",
        es: "Lagna",
        ru: {
            default: "Лагна",
            in: "Лагне",
            with: "Лагной",
        },
        en: "Lagna",
    },
    Плутон: {
        pt: "Plutão",
        es: "Plutón",
        ru: {
            default: "Плутон",
            in: "Плутоне",
            with: "Плутоном",
        },
        en: "Pluto",
    },
    тригон: {
        pt: "trígono",
        es: "trino",
        ru: {
            default: "тригон",
            in: "тригоне",
            with: "тригоном",
        },
        en: "trine",
    },
    секстиль: {
        pt: "sextil",
        es: "sextil",
        ru: {
            default: "секстиль",
            in: "секстиле",
            with: "секстилем",
        },
        en: "sextile",
    },
    соединение: {
        pt: "conjunção",
        es: "conjunción",
        ru: {
            default: "соединение",
            in: "соединении",
            with: "соединением",
        },
        en: "conjunction",
    },
    оппозиция: {
        pt: "oposição",
        es: "oposición",
        ru: {
            default: "оппозиция",
            in: "оппозиции",
            with: "оппозицией",
        },
        en: "opposition",
    },
    напротив: {
        pt: "oposto",
        es: "opuesto",
        ru: {
            default: "напротив",
            in: "противоположности",
            with: "напротив",
        },
        en: "opposite",
    },
    квадрат: {
        pt: "quadratura",
        es: "cuadratura",
        ru: {
            default: "квадрат",
            in: "квадрате",
            with: "квадратом",
        },
        en: "square",
    },
    несоединение: {
        pt: "inconjunto",
        es: "inconjunto",
        ru: {
            default: "несоединение",
            in: "несоединении",
            with: "несоединением",
        },
        en: "inconjunct",
    },
    полуквадрат: {
        pt: "semiquadratura",
        es: "semicuadratura",
        ru: {
            default: "полуквадрат",
            in: "полуквадрате",
            with: "полуквадратом",
        },
        en: "semisquare",
    },
    natal_chart_short_template: {
        pt: `Mapa Natal: {{firstName}} {{lastName}}
    Data de nascimento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Cidade: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sol em {{sun.sign_name}} na casa {{sun.house_position}}
    💫 Ascendente em {{ascendant.sign_name}}
    🌓 Lua em {{moon.sign_name}} na casa {{moon.house_position}}
    🌑 Vênus {{venus.sign_name}} na casa {{venus.house_position}}
    🌑 Mercúrio em {{mercury.sign_name}} na casa {{mercury.house_position}}
    🌑 Marte em {{mars.sign_name}} na casa {{mars.house_position}}
    🌑 Júpiter em {{jupiter.sign_name}} na casa {{jupiter.house_position}}
    🪐 Saturno em {{saturn.sign_name}} na casa {{saturn.house_position}}
    🌑 Urano em {{uranus.sign_name}} na casa {{uranus.house_position}}
    🌑 Netuno em {{neptune.sign_name}} na casa {{neptune.house_position}}`,
        es: `Carta Natal: {{firstName}} {{lastName}}
    Fecha de nacimiento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Ciudad: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sol en {{sun.sign_name}} en la casa {{sun.house_position}}
    💫 Ascendente en {{ascendant.sign_name}}
    🌓 Luna en {{moon.sign_name}} en la casa {{moon.house_position}}
    🌑 Venus en {{venus.sign_name}} en la casa {{venus.house_position}}
    🌑 Mercurio en {{mercury.sign_name}} en la casa {{mercury.house_position}}
    🌑 Marte en {{mars.sign_name}} en la casa {{mars.house_position}}
    🌑 Júpiter en {{jupiter.sign_name}} en la casa {{jupiter.house_position}}
    🪐 Saturno en {{saturn.sign_name}} en la casa {{saturn.house_position}}
    🌑 Urano en {{uranus.sign_name}} en la casa {{uranus.house_position}}
    🌑 Neptuno en {{neptune.sign_name}} en la casa {{neptune.house_position}}`,
        ru: `Натальная Карта: {{firstName}} {{lastName}}
    Дата рождения: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Город: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Солнце в {{suffixRuIn sun.sign_name}} в доме {{suffixRuIn sun.house_position}}
    💫 Асцендент в {{suffixRuIn ascendant.sign_name}}
    🌓 Луна в {{suffixRuIn moon.sign_name}} в доме {{moon.house_position}}
    🌑 Венера в {{suffixRuIn venus.sign_name}} в доме {{venus.house_position}}
    🌑 Меркурий в {{suffixRuIn mercury.sign_name}} в доме {{mercury.house_position}}
    🌑 Марс в {{suffixRuIn mars.sign_name}} в доме {{mars.house_position}}
    🌑 Юпитер в {{suffixRuIn jupiter.sign_name}} в доме {{jupiter.house_position}}
    🪐 Сатурн в {{suffixRuIn saturn.sign_name}} в доме {{saturn.house_position}}
    🌑 Уран в {{suffixRuIn uranus.sign_name}} в доме {{uranus.house_position}}
    🌑 Нептун в {{suffixRuIn neptune.sign_name}} в доме {{neptune.house_position}}`,
        en: `Natal Chart: {{firstName}} {{lastName}}
    Birth date: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    City: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sun in {{sun.sign_name}} in the house {{sun.house_position}}
    💫 Ascendant in {{ascendant.sign_name}}
    🌓 Moon in {{moon.sign_name}} in the house {{moon.house_position}}
    🌑 Venus in {{venus.sign_name}} in the house {{venus.house_position}}
    🌑 Mercury in {{mercury.sign_name}} in the house {{mercury.house_position}}
    🌑 Mars in {{mars.sign_name}} in the house {{mars.house_position}}
    🌑 Jupiter in {{jupiter.sign_name}} in the house {{jupiter.house_position}}
    🪐 Saturn in {{saturn.sign_name}} in the house {{saturn.house_position}}
    🌑 Uranus in {{uranus.sign_name}} in the house {{uranus.house_position}}
    🌑 Neptune in {{neptune.sign_name}} in the house {{neptune.house_position}}`,
    },
    natal_chart_vedic_short_template: {
        pt: `Mapa Natal: {{firstName}} {{lastName}}
    Data de nascimento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Cidade: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sol em {{sun.sign_name}} na casa {{sun.house_position}}
    💫 Lagna em {{lagna.sign_name}} na casa {{lagna.house_position}}
    🌓 Lua em {{moon.sign_name}} na casa {{moon.house_position}}
    🌑 Vênus {{venus.sign_name}} na casa {{venus.house_position}}
    🌑 Mercúrio em {{mercury.sign_name}} na casa {{mercury.house_position}}
    🌑 Marte em {{mars.sign_name}} na casa {{mars.house_position}}
    🌑 Júpiter em {{jupiter.sign_name}} na casa {{jupiter.house_position}}
    🪐 Saturno em {{saturn.sign_name}} na casa {{saturn.house_position}}
    🐉 Rahu em {{rahu.sign_name}} na casa {{rahu.house_position}}
    🔮 Ceto em {{ketu.sign_name}} na casa {{ketu.house_position}}`,
        es: `Carta Natal: {{firstName}} {{lastName}}
    Fecha de nacimiento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Ciudad: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sol en {{sun.sign_name}} en la casa {{sun.house_position}}
    💫 Lagna en {{lagna.sign_name}} en la casa {{lagna.house_position}}
    🌓 Luna en {{moon.sign_name}} en la casa {{moon.house_position}}
    🌑 Venus en {{venus.sign_name}} en la casa {{venus.house_position}}
    🌑 Mercurio en {{mercury.sign_name}} en la casa {{mercury.house_position}}
    🌑 Marte en {{mars.sign_name}} en la casa {{mars.house_position}}
    🌑 Júpiter en {{jupiter.sign_name}} en la casa {{jupiter.house_position}}
    🪐 Saturno en {{saturn.sign_name}} en la casa {{saturn.house_position}}
    🐉 Rahu en {{rahu.sign_name}} en la casa {{rahu.house_position}}
    🔮 Ketu en {{ketu.sign_name}} en la casa {{ketu.house_position}}`,
        ru: `Натальная Карта: {{firstName}} {{lastName}}
    Дата рождения: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Город: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Солнце в {{sun.sign_name}} в доме {{sun.house_position}}
    💫 Лагна в {{lagna.sign_name}} в доме {{lagna.house_position}}
    🌓 Луна в {{moon.sign_name}} в доме {{moon.house_position}}
    🌑 Венера в {{venus.sign_name}} в доме {{venus.house_position}}
    🌑 Меркурий в {{mercury.sign_name}} в доме {{mercury.house_position}}
    🌑 Марс в {{mars.sign_name}} в доме {{mars.house_position}}
    🌑 Юпитер в {{jupiter.sign_name}} в доме {{jupiter.house_position}}
    🪐 Сатурн в {{saturn.sign_name}} в доме {{saturn.house_position}}
    🐉 Раху в {{rahu.sign_name}} в доме {{rahu.house_position}}
    🔮 Кету в {{ketu.sign_name}} в доме {{ketu.house_position}}`,
        en: `Natal Chart: {{firstName}} {{lastName}}
    Birth date: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    City: {{birthData.cityName}}, {{birthData.countryName}}
    🔆 Sun in {{sun.sign_name}} in the house {{sun.house_position}}
    💫 Lagna in {{lagna.sign_name}} in the house {{lagna.house_position}}
    🌓 Moon in {{moon.sign_name}} in the house {{moon.house_position}}
    🌑 Venus in {{venus.sign_name}} in the house {{venus.house_position}}
    🌑 Mercury in {{mercury.sign_name}} in the house {{mercury.house_position}}
    🌑 Mars in {{mars.sign_name}} in the house {{mars.house_position}}
    🌑 Jupiter in {{jupiter.sign_name}} in the house {{jupiter.house_position}}
    🪐 Saturn in {{saturn.sign_name}} in the house {{saturn.house_position}}
    🐉 Rahu in {{rahu.sign_name}} in the house {{rahu.house_position}}
    🔮 Ketu in {{ketu.sign_name}} in the house {{ketu.house_position}}`,
    },
    natal_chart_birth_data: {
        pt: `Mapa Natal: {{firstName}} {{lastName}}
    Data de nascimento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Cidade: {{birthData.cityName}}, {{birthData.countryName}}`,
        es: `Carta Natal: {{firstName}} {{lastName}}
    Fecha de nacimiento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Ciudad: {{birthData.cityName}}, {{birthData.countryName}}`,
        ru: `Натальная Карта: {{firstName}} {{lastName}}
    Дата рождения: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Город: {{birthData.cityName}}, {{birthData.countryName}}`,
        en: `Natal Chart: {{firstName}} {{lastName}}
    Birth date: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    City: {{birthData.cityName}}, {{birthData.countryName}}`,
    },
    extended_natal_chart_template: {
        pt: `Mapa Natal: {{firstName}} {{lastName}}
    {{#if chartTypeTitle}}{{chartTypeTitle}}{{/if}}
    Data de nascimento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Cidade: {{birthData.cityName}}, {{birthData.countryName}}
    ☀️ Sol em {{planets.sun.sign}} na casa {{planets.sun.house}}
    🌄 Ascendente em {{ascendant.sign}}
    🌙 Lua em {{planets.moon.sign}} na casa {{planets.moon.house}}
    💕 Vênus em {{planets.venus.sign}} na casa {{planets.venus.house}}
    🖋 Mercúrio em {{planets.mercury.sign}} na casa {{planets.mercury.house}}{{#if planets.mercury.retrograde}} (retrógrado){{/if}}
    ⚔️ Marte em {{planets.mars.sign}} na casa {{planets.mars.house}}
    🍀 Júpiter em {{planets.jupiter.sign}} na casa {{planets.jupiter.house}}{{#if planets.jupiter.retrograde}} (retrógrado){{/if}}
    🕰 Saturno em {{planets.saturn.sign}} na casa {{planets.saturn.house}}
    🌪 Urano em {{planets.uranus.sign}} na casa {{planets.uranus.house}}
    🌊 Netuno em {{planets.neptune.sign}} na casa {{planets.neptune.house}}
    ♇ Plutão em {{planets.pluto.sign}} na casa {{planets.pluto.house}}{{#if planets.pluto.retrograde}} (retrógrado){{/if}}
    {{#if points.northNode}}☊ Nodo Norte em {{points.northNode.sign}} na casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sul em {{points.southNode.sign}} na casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith em {{points.lilith.sign}} na casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quíron em {{points.chiron.sign}} na casa {{points.chiron.house}}{{/if}}
    
    Aspectos:
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    
    Cúspides e governantes das casas (sistema moderno):
    {{#each houses}}
    Casa {{@key}}: Cúspide em {{this.sign}}: Governante - {{this.ruler}}
    {{/each}}
    `,
        es: `Carta Natal: {{firstName}} {{lastName}}
    {{#if chartTypeTitle}}{{chartTypeTitle}}{{/if}}
    Fecha de nacimiento: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Ciudad: {{birthData.cityName}}, {{birthData.countryName}}
    ☀️ Sol en {{planets.sun.sign}} en la casa {{planets.sun.house}}
    🌄 Ascendente en {{ascendant.sign}}
    🌙 Luna en {{planets.moon.sign}} en la casa {{planets.moon.house}}
    💕 Venus en {{planets.venus.sign}} en la casa {{planets.venus.house}}
    🖋 Mercurio en {{planets.mercury.sign}} en la casa {{planets.mercury.house}}{{#if planets.mercury.retrograde}} (retrógrado){{/if}}
    ⚔️ Marte en {{planets.mars.sign}} en la casa {{planets.mars.house}}
    🍀 Júpiter en {{planets.jupiter.sign}} en la casa {{planets.jupiter.house}}{{#if planets.jupiter.retrograde}} (retrógrado){{/if}}
    🕰 Saturno en {{planets.saturn.sign}} en la casa {{planets.saturn.house}}
    🌪 Urano en {{planets.uranus.sign}} en la casa {{planets.uranus.house}}
    🌊 Neptuno en {{planets.neptune.sign}} en la casa {{planets.neptune.house}}
    ♇ Plutón en {{planets.pluto.sign}} en la casa {{planets.pluto.house}}{{#if planets.pluto.retrograde}} (retrógrado){{/if}}
    {{#if points.northNode}}☊ Nodo Norte en {{points.northNode.sign}} en la casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sur en {{points.southNode.sign}} en la casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith en {{points.lilith.sign}} en la casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quirón en {{points.chiron.sign}} en la casa {{points.chiron.house}}{{/if}}
    
    Aspectos:
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    
    Cúspides y gobernantes de las casas (sistema moderno):
    {{#each houses}}
    Casa {{@key}}: Cúspide en {{this.sign}}: Gobernante - {{this.ruler}}
    {{/each}}
    `,
        ru: `Натальная Карта: {{firstName}} {{lastName}}
    {{#if chartTypeTitle}}{{chartTypeTitle}}{{/if}}
    Дата рождения: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    Город: {{birthData.cityName}}, {{birthData.countryName}}
    ☀️ Солнце в {{suffixRuIn planets.sun.sign}} в {{planets.sun.house}} доме
    🌄 Асцендент в {{suffixRuIn ascendant.sign}}
    🌙 Луна в {{suffixRuIn planets.moon.sign}} в {{planets.moon.house}} доме
    💕 Венера в {{suffixRuIn planets.venus.sign}} в {{planets.venus.house}} доме
    🖋 Меркурий в {{suffixRuIn planets.mercury.sign}} в {{planets.mercury.house}} доме{{#if planets.mercury.retrograde}} (ретроградный){{/if}}
    ⚔️ Марс в {{suffixRuIn planets.mars.sign}} в {{planets.mars.house}} доме
    🍀 Юпитер в {{suffixRuIn planets.jupiter.sign}} в {{planets.jupiter.house}} доме{{#if planets.jupiter.retrograde}} (ретроградный){{/if}}
    🕰 Сатурн в {{suffixRuIn planets.saturn.sign}} в {{planets.saturn.house}} доме
    🌪 Уран в {{suffixRuIn planets.uranus.sign}} в {{planets.uranus.house}} доме
    🌊 Нептун в {{suffixRuIn planets.neptune.sign}} в {{planets.neptune.house}} доме
    ♇ Плутон в {{suffixRuIn planets.pluto.sign}} в {{planets.pluto.house}} доме{{#if planets.pluto.retrograde}} (ретроградный){{/if}}
    {{#if points.northNode}}☊ Северный узел в {{suffixRuIn points.northNode.sign}} в {{points.northNode.house}} доме{{/if}}
    {{#if points.southNode}}☋ Южный узел в {{suffixRuIn points.southNode.sign}} в {{points.southNode.house}} доме{{/if}}
    {{#if points.lilith}}🌑 Лилит в {{suffixRuIn points.lilith.sign}} в {{points.lilith.house}} доме{{/if}}
    {{#if points.chiron}}🔑 Хирон в {{suffixRuIn points.chiron.sign}} в {{points.chiron.house}} доме{{/if}}
    
    Аспекты:
    {{#each aspects}}
    - {{this.planet1}} в {{suffixRuIn this.type}} с {{suffixRuWith this.planet2}}
    {{/each}}
    
    Куспиды и правители домов (современная система):
    {{#each houses}}
    Дом {{@key}}: Куспид в {{suffixRuIn this.sign}}: Правитель - {{this.ruler}}
    {{/each}}
    `,
        en: `Natal Chart: {{firstName}} {{lastName}}
    {{#if chartTypeTitle}}{{chartTypeTitle}}{{/if}}
    Birth date: {{birthData.day}}.{{birthData.month}}.{{birthData.year}} {{birthData.hour}}:{{birthData.minute}}
    City: {{birthData.cityName}}, {{birthData.countryName}}
    ☀️ Sun in {{planets.sun.sign}} in the {{planets.sun.house}}th house
    🌄 Ascendant in {{ascendant.sign}}
    🌙 Moon in {{planets.moon.sign}} in the {{planets.moon.house}}th house
    💕 Venus in {{planets.venus.sign}} in the {{planets.venus.house}}th house
    🖋 Mercury in {{planets.mercury.sign}} in the {{planets.mercury.house}}th house{{#if planets.mercury.retrograde}} (retrograde){{/if}}
    ⚔️ Mars in {{planets.mars.sign}} in the {{planets.mars.house}}th house
    🍀 Jupiter in {{planets.jupiter.sign}} in the {{planets.jupiter.house}}th house{{#if planets.jupiter.retrograde}} (retrograde){{/if}}
    🕰 Saturn in {{planets.saturn.sign}} in the {{planets.saturn.house}}th house
    🌪 Uranus in {{planets.uranus.sign}} in the {{planets.uranus.house}}th house
    🌊 Neptune in {{planets.neptune.sign}} in the {{planets.neptune.house}}th house
    ♇ Pluto in {{planets.pluto.sign}} in the {{planets.pluto.house}}th house{{#if planets.pluto.retrograde}} (retrograde){{/if}}
    {{#if points.northNode}}☊ North Node in {{points.northNode.sign}} in the {{points.northNode.house}}th house{{/if}}
    {{#if points.southNode}}☋ South Node in {{points.southNode.sign}} in the {{points.southNode.house}}th house{{/if}}
    {{#if points.lilith}}🌑 Lilith  in {{points.lilith.sign}} in the {{points.lilith.house}}th house{{/if}}
    {{#if points.chiron}}🔑 Chiron in {{points.chiron.sign}} in the {{points.chiron.house}}th house{{/if}}
    
    Aspects:
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    
    Cusps and rulers of houses (modern system):
    {{#each houses}}
    House {{@key}}: Cusp in {{this.sign}}: Ruler - {{this.ruler}}
    {{/each}}
    `,
    },
    extended_natal_chart_planets_template: {
        en: `
    ☀️ Sun in {{planets.sun.sign}} in the {{planets.sun.house}}th house
    🌄 Ascendant in {{ascendant.sign}}
    🌙 Moon in {{planets.moon.sign}} in the {{planets.moon.house}}th house
    💕 Venus in {{planets.venus.sign}} in the {{planets.venus.house}}th house
    🖋 Mercury in {{planets.mercury.sign}} in the {{planets.mercury.house}}th house{{#if planets.mercury.retrograde}} (retrograde){{/if}}
    ⚔️ Mars in {{planets.mars.sign}} in the {{planets.mars.house}}th house
    🍀 Jupiter in {{planets.jupiter.sign}} in the {{planets.jupiter.house}}th house{{#if planets.jupiter.retrograde}} (retrograde){{/if}}
    🕰 Saturn in {{planets.saturn.sign}} in the {{planets.saturn.house}}th house
    🌪 Uranus in {{planets.uranus.sign}} in the {{planets.uranus.house}}th house
    🌊 Neptune in {{planets.neptune.sign}} in the {{planets.neptune.house}}th house
    ♇ Pluto in {{planets.pluto.sign}} in the {{planets.pluto.house}}th house{{#if planets.pluto.retrograde}} (retrograde){{/if}}
    {{#if points.northNode}}☊ North Node in {{points.northNode.sign}} in the {{points.northNode.house}}th house{{/if}}
    {{#if points.southNode}}☋ South Node in {{points.southNode.sign}} in the {{points.southNode.house}}th house{{/if}}
    {{#if points.lilith}}🌑 Lilith  in {{points.lilith.sign}} in the {{points.lilith.house}}th house{{/if}}
    {{#if points.chiron}}🔑 Chiron in {{points.chiron.sign}} in the {{points.chiron.house}}th house{{/if}}
    `,
        pt: `
    ☀️ Sol em {{planets.sun.sign}} na casa {{planets.sun.house}}
    🌄 Ascendente em {{ascendant.sign}}
    🌙 Lua em {{planets.moon.sign}} na casa {{planets.moon.house}}
    💕 Vênus em {{planets.venus.sign}} na casa {{planets.venus.house}}
    🖋 Mercúrio em {{planets.mercury.sign}} na casa {{planets.mercury.house}}{{#if planets.mercury.retrograde}} (retrógrado){{/if}}
    ⚔️ Marte em {{planets.mars.sign}} na casa {{planets.mars.house}}
    🍀 Júpiter em {{planets.jupiter.sign}} na casa {{planets.jupiter.house}}{{#if planets.jupiter.retrograde}} (retrógrado){{/if}}
    🕰 Saturno em {{planets.saturn.sign}} na casa {{planets.saturn.house}}
    🌪 Urano em {{planets.uranus.sign}} na casa {{planets.uranus.house}}
    🌊 Netuno em {{planets.neptune.sign}} na casa {{planets.neptune.house}}
    ♇ Plutão em {{planets.pluto.sign}} na casa {{planets.pluto.house}}{{#if planets.pluto.retrograde}} (retrógrado){{/if}}
    {{#if points.northNode}}☊ Nodo Norte em {{points.northNode.sign}} na casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sul em {{points.southNode.sign}} na casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith em {{points.lilith.sign}} na casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quíron em {{points.chiron.sign}} na casa {{points.chiron.house}}{{/if}}
    `,
        es: `
    ☀️ Sol en {{planets.sun.sign}} en la casa {{planets.sun.house}}
    🌄 Ascendente en {{ascendant.sign}}
    🌙 Luna en {{planets.moon.sign}} en la casa {{planets.moon.house}}
    💕 Venus en {{planets.venus.sign}} en la casa {{planets.venus.house}}
    🖋 Mercurio en {{planets.mercury.sign}} en la casa {{planets.mercury.house}}{{#if planets.mercury.retrograde}} (retrógrado){{/if}}
    ⚔️ Marte en {{planets.mars.sign}} en la casa {{planets.mars.house}}
    🍀 Júpiter en {{planets.jupiter.sign}} en la casa {{planets.jupiter.house}}{{#if planets.jupiter.retrograde}} (retrógrado){{/if}}
    🕰 Saturno en {{planets.saturn.sign}} en la casa {{planets.saturn.house}}
    🌪 Urano en {{planets.uranus.sign}} en la casa {{planets.uranus.house}}
    🌊 Neptuno en {{planets.neptune.sign}} en la casa {{planets.neptune.house}}
    ♇ Plutón en {{planets.pluto.sign}} en la casa {{planets.pluto.house}}{{#if planets.pluto.retrograde}} (retrógrado){{/if}}
    {{#if points.northNode}}☊ Nodo Norte en {{points.northNode.sign}} en la casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sur en {{points.southNode.sign}} en la casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith en {{points.lilith.sign}} en la casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quirón en {{points.chiron.sign}} en la casa {{points.chiron.house}}{{/if}}
    `,
        ru: `
    ☀️ Солнце в {{suffixRuIn planets.sun.sign}} в {{planets.sun.house}} доме
    🌄 Асцендент в {{suffixRuIn ascendant.sign}}
    🌙 Луна в {{suffixRuIn planets.moon.sign}} в {{planets.moon.house}} доме
    💕 Венера в {{suffixRuIn planets.venus.sign}} в {{planets.venus.house}} доме
    🖋 Меркурий в {{suffixRuIn planets.mercury.sign}} в {{planets.mercury.house}} доме{{#if planets.mercury.retrograde}} (ретроградный){{/if}}
    ⚔️ Марс в {{suffixRuIn planets.mars.sign}} в {{planets.mars.house}} доме
    🍀 Юпитер в {{suffixRuIn planets.jupiter.sign}} в {{planets.jupiter.house}} доме{{#if planets.jupiter.retrograde}} (ретроградный){{/if}}
    🕰 Сатурн в {{suffixRuIn planets.saturn.sign}} в {{planets.saturn.house}} доме
    🌪 Уран в {{suffixRuIn planets.uranus.sign}} в {{planets.uranus.house}} доме
    🌊 Нептун в {{suffixRuIn planets.neptune.sign}} в {{planets.neptune.house}} доме
    ♇ Плутон в {{suffixRuIn planets.pluto.sign}} в {{planets.pluto.house}} доме{{#if planets.pluto.retrograde}} (ретроградный){{/if}}
    {{#if points.northNode}}☊ Северный узел в {{suffixRuIn points.northNode.sign}} в {{points.northNode.house}} доме{{/if}}
    {{#if points.southNode}}☋ Южный узел в {{suffixRuIn points.southNode.sign}} в {{points.southNode.house}} доме{{/if}}
    {{#if points.lilith}}🌑 Лилит в {{suffixRuIn points.lilith.sign}} в {{points.lilith.house}} доме{{/if}}
    {{#if points.chiron}}🔑 Хирон в {{suffixRuIn points.chiron.sign}} в {{points.chiron.house}} доме{{/if}}
    `,
    },
    extended_natal_chart_aspects_template: {
        pt: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
        es: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
        ru: `
    {{#each aspects}}
    - {{this.planet1}} в {{suffixRuIn this.type}} с {{suffixRuWith this.planet2}}
    {{/each}}
    `,
        en: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
    },
    extended_natal_chart_single_planet_aspects_template: {
        pt: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
        es: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
        ru: `
    {{#each aspects}}
    - {{this.planet1}} в {{suffixRuIn this.type}} с {{suffixRuWith this.planet2}}
    {{/each}}
    `,
        en: `
    {{#each aspects}}
    - {{this.planet1}} {{this.type}} {{this.planet2}}
    {{/each}}
    `,
    },
    extended_natal_chart_cusps_template: {
        pt: `Cúspides e governantes das casas (sistema moderno):
    {{#each houses}}
    Casa {{@key}}: Cúspide em {{this.sign}}: Governante - {{this.ruler}}
    {{/each}}
    `,
        es: `Cúspides y gobernantes de las casas (sistema moderno):
    {{#each houses}}
    Casa {{@key}}: Cúspide en {{this.sign}}: Gobernante - {{this.ruler}}
    {{/each}}
    `,
        ru: `Куспиды и правители домов (современная система):
    {{#each houses}}
    Дом {{@key}}: Куспид в {{suffixRuIn this.sign}}: Правитель - {{this.ruler}}
    {{/each}}
    `,
        en: `Cusps and rulers of houses (modern system):
    {{#each houses}}
    House {{@key}}: Cusp in {{this.sign}}: Ruler - {{this.ruler}}
    {{/each}}
    `,
    },
    extended_natal_chart_points_template: {
        en: `{{#if points.northNode}}☊ North Node in {{points.northNode.sign}} in the {{points.northNode.house}}th house{{/if}}
    {{#if points.southNode}}☋ South Node in {{points.southNode.sign}} in the {{points.southNode.house}}th house{{/if}}
    {{#if points.lilith}}🌑 Lilith  in {{points.lilith.sign}} in the {{points.lilith.house}}th house{{/if}}
    {{#if points.chiron}}🔑 Chiron in {{points.chiron.sign}} in the {{points.chiron.house}}th house{{/if}}
    `,
        pt: `{{#if points.northNode}}☊ Nodo Norte em {{points.northNode.sign}} na casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sul em {{points.southNode.sign}} na casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith em {{points.lilith.sign}} na casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quíron em {{points.chiron.sign}} na casa {{points.chiron.house}}{{/if}}
    `,
        es: `{{#if points.northNode}}☊ Nodo Norte en {{points.northNode.sign}} en la casa {{points.northNode.house}}{{/if}}
    {{#if points.southNode}}☋ Nodo Sur en {{points.southNode.sign}} en la casa {{points.southNode.house}}{{/if}}
    {{#if points.lilith}}🌑 Lilith en {{points.lilith.sign}} en la casa {{points.lilith.house}}{{/if}}
    {{#if points.chiron}}🔑 Quirón en {{points.chiron.sign}} en la casa {{points.chiron.house}}{{/if}}
    `,
        ru: `{{#if points.northNode}}☊ Северный узел в {{suffixRuIn points.northNode.sign}} в {{points.northNode.house}} доме{{/if}}
    {{#if points.southNode}}☋ Южный узел в {{suffixRuIn points.southNode.sign}} в {{points.southNode.house}} доме{{/if}}
    {{#if points.lilith}}🌑 Лилит в {{suffixRuIn points.lilith.sign}} в {{points.lilith.house}} доме{{/if}}
    {{#if points.chiron}}🔑 Хирон в {{suffixRuIn points.chiron.sign}} в {{points.chiron.house}} доме{{/if}}
    `,
    },
};

export const quizOfferTranslations = {
    blur_example_headline: {
        pt: "Veja o que você aprenderá com o relatório",
        es: "Vea lo que aprenderá del informe",
        ru: "Вот что вы узнаете из этой расшифровки",
        en: "Here's what you'll learn",
    },
    blur_example_item_1_text: {
        pt: `
## Com quais signos do zodíaco você tem a melhor compatibilidade?
Touro. Compatibilidade: moderada. 
Este signo valoriza a estabilidade e a fidelidade tanto quanto você, o que cria uma base sólida para relacionamentos fortes e harmoniosos. [blur:Хххххх ххххх хххххх ххххххх ххх ххх]

* *
[blur:ХХХХ]. Compatibilidade: alta
[blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх. Ххххх ххххх хххххххх, ххх ххххххх ххххх хх ххххххх. Х ххх-хх ххх ххх ххххх ххххх х хххххх, ххх хх хххххх хххх ххххх ххххх. Ххххххххххххххххх х ххххххххх ххх ххх ххх ххххх.]

**Quem será seu parceiro ideal?**
**Receba respostas agora mesmo!**`,
        es: `
## ¿Con qué signos del zodíaco tienes mejor compatibilidad?
Tauro. Compatibilidad: moderada.
Este signo valora la estabilidad y la fidelidad tanto como tú, lo que crea una base sólida para relaciones sólidas y armoniosas.  [blur:Хххххх ххххх хххххх ххххххх ххх ххх]

* *
[blur:ХХХХ]. Compatibilidad: alta
[blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх. Ххххх ххххх хххххххх, ххх ххххххх ххххх хх ххххххх. Х ххх-хх ххх ххх ххххх ххххх х хххххх, ххх хх хххххх хххх ххххх ххххх. Ххххххххххххххххх х ххххххххх ххх ххх ххх ххххх.]

**¿Quién será tu pareja ideal?**
**¡Obtén respuestas ahora mismo!**`,
        ru: `
## С какими знаками зодиака у вас наилучшая совместимость?
Телец. Совместимость: умеренная.
Этот знак ценит стабильность и верность так же, как и вы, что создает прочный фундамент для крепких, гармоничных отношений. [blur:Хххххх ххххх хххххх ххххххх ххх ххх]

* *
[blur:ХХХХ]. Совместимость: высокая
[blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх. Ххххх ххххх хххххххх, ххх ххххххх ххххх хх ххххххх. Х ххх-хх ххх ххх ххххх ххххх х хххххх, ххх хх хххххх хххх ххххх ххххх. Ххххххххххххххххх х ххххххххх ххх ххх ххх ххххх.]

**Кто станет вашим идеальным партнером?**
**Получите ответы прямо сейчас!**`,
        en: `
## Which zodiac signs do you have the best compatibility with?
Taurus. Compatibility: medium.
This sign values stability and loyalty as much as you do, which creates a solid foundation for a strong, harmonious relationship. [blur:Хххххх ххххх хххххх ххххххх ххх ххх]

* *
[blur:ХХХХ]. Compatibility: high
[blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх. Ххххх ххххх хххххххх, ххх ххххххх ххххх хх ххххххх. Х ххх-хх ххх ххх ххххх ххххх х хххххх, ххх хх хххххх хххх ххххх ххххх. Ххххххххххххххххх х ххххххххх ххх ххх ххх ххххх.]

**Who will be your ideal partner?**
**Get the answers now!**`,
    },
    blur_example_item_2_text: {
        pt: `
## Como se abrir completamente em relacionamentos sexuais?
Em termos de sexualidade, você é mais compatível com os seguintes signos do zodíaco:

1. [blur:хххххххххххххххх]
2. [blur:хххххххх, хххххххххххх]
3. [blur:хххххххххххххххххххх]
* *
* *
> Recomendações do seu astrólogo:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Receba recomendações do seu astrólogo e descubra o que você realmente deseja em termos de intimidade e como liberar sua energia sexual para o mundo exterior.**`,
        es: `
## ¿Cómo abrirse completamente en las relaciones sexuales?
En términos de sexualidad, eres más compatible con los siguientes signos del zodíaco:

1. [blur:хххххххххххххххх]
2. [blur:хххххххх, хххххххххххх]
3. [blur:хххххххххххххххххххх]
* *
* *
> Recomendaciones de su astrólogo:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Recibe recomendaciones de tu astrólogo y descubre lo que realmente deseas en términos de intimidad y cómo liberar tu energía sexual al mundo exterior.**`,
        ru: `
## Как полностью раскрыться в сексуальных отношениях?
В сексуальном плане вы наиболее совместимы со следующими знаками зодиака:

1. [blur:хххххххххххххххх]
2. [blur:хххххххх, хххххххххххх]
3. [blur:хххххххххххххххххххх]
* *
* *
> Рекомендации от вашего астролога:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Получите рекомендации астролога и узнайте, чего вы действительно хотите в плане сексуальной жизни и поймите, как выпустить свою сексуальную энергию во внешний мир.**`,
        en: `
## How do you fully explore yourself in a sexual relationship?
Sexually, you are most compatible with the following zodiac signs:

1. [blur:хххххххххххххххх]
2. [blur:хххххххх, хххххххххххх]
3. [blur:хххххххххххххххххххх]
* *
* *
> Recommendations from your astrologer:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Get guidance from an astrologer and find out what you really want in terms of your sex life and understand how to release your sexual energy to the outside world.**`,
    },
    blur_example_item_3_text: {
        pt: `
## Em quais profissões você terá sucesso e ganhará uma vida luxuosa?

Nessas profissões, você encontrará interesse em se desenvolver, demonstrará as suas habilidades, alcançará o sucesso rapidamente e será capaz de ganhar dinheiro com facilidade.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]

* *
Nessas profissões, você se sentirá infeliz, não terá motivação nem crescimento na carreira, cada dia será parecido com o anterior e será difícil ganhar dinheiro de forma digna.

1. [blur:Ххххххх, хххххх]
2. [blur:Ххххххх]
3. [blur:Ххххххх ххххххxxxx]

**Descubra como ter sucesso na vida e encontrar o caminho para a abundância.**`,
        es: `
## ¿En qué profesiones tendrás éxito y ganarás una vida lujosa?

En estas profesiones encontrarás interés en desarrollarte, demostrarás tus habilidades, alcanzarás el éxito rápidamente y podrás ganar dinero fácilmente.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]

* *
En estas profesiones te sentirás infeliz, no tendrás motivación ni crecimiento profesional, cada día será parecido al anterior y será difícil ganar dinero de forma digna.

1. [blur:Ххххххх, хххххх]
2. [blur:Ххххххх]
3. [blur:Ххххххх ххххххxxxx]

**Descubre cómo tener éxito en la vida y encontrar el camino hacia la abundancia.**`,
        ru: `
## В каких профессиях вы сможете добиться успеха и заработать на роскошную жизнь?

В этих профессиях вы получите самореализацию, проявите свои способности, быстро добьетесь успеха и сможете легко зарабатывать деньги.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]

* *
В этих профессиях вы будете несчастны, у вас не будет мотивации и карьерного роста, каждый день будет таким же, как предыдущий, и заработать достойные деньги будет сложно.

1. [blur:Ххххххх, хххххх]
2. [blur:Ххххххх]
3. [blur:Ххххххх ххххххxxxx]

**Узнайте, как добиться успеха в жизни и найти путь к изобилию! Получите полную расшифровку вашей натальной карты.**`,
        en: `
## In which professions will you be able to succeed and earn for rich life?

In these professions you will get fulfilment, show your abilities, succeed quickly and earn money easily.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]

* *
In these professions you will be unhappy, you will have no motivation and career growth, every day will be the same as the previous one and it will be difficult to earn decent money.

1. [blur:Ххххххх, хххххх]
2. [blur:Ххххххх]
3. [blur:Ххххххх ххххххxxxx]

**Learn how to succeed in life and find the path to abundance! Get a complete analysis of your natal chart.**`,
    },
    blur_example_item_4_text: {
        pt: `
## Seus pontos fortes e fracos
Você tem um caráter fantástico e talento! Compreender seus pontos fortes e fracos permitirá que você tenha sucesso em relacionamentos, no trabalho e autodesenvolvimento.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]
* *
* *
> Recomendações do seu astrólogo:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Saiba como usar seus pontos fortes para alcançar seus objetivos de vida!**`,
        es: `
## Tus fortalezas y debilidades
¡Tienes un carácter y un talento fantásticos! Comprender sus fortalezas y debilidades le permitirá tener éxito en las relaciones, en el trabajo y en el autodesarrollo.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]
* *
* *
> Recomendaciones de su astrólogo:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**¡Aprenda a utilizar sus fortalezas para lograr sus objetivos de vida!**`,
        ru: `
## Ваши сильные и слабые стороны
У вас фантастический характер и талант! Понимание своих сильных и слабых сторон позволит вам добиться успеха в отношениях, на работе и в саморазвитии.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]
* *
* *
> Рекомендации от вашего астролога:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Узнайте, как использовать свои сильные стороны для достижения жизненных целей!**`,
        en: `
## Your strengths and weaknesses
You have fantastic character and talent! Understanding your strengths and weaknesses will enable you to succeed in relationships, work and self-development.

1. [blur:Ххххххх хххххх]
2. [blur:Ххххххххххх]
3. [blur:Ххххххх ххххх, хххххх]
* *
* *
> Recommendations from your astrologer:
> [blur:Хххххх ххххх хххххх ххххххх ххх ххх ххххххххх х ххх х хххх хххххххххх.]
* *
**Learn how to use your strengths to achieve your life goals!**`,
    },
    blur_example_item_5_text: {
        ru: `
## Что еще ты узнаешь из своей натальной карты
**Ваша уникальность** - Узнайте, что говорят о вас звезды. Описание вашей уникальной личности исходя из вашей натальной карты
\t
**Ваши сильные стороны** - В чем ваша сила? Что у вас получается лучше всего?
\t
**Ваши слабые стороны** - Что в вашем характере может причинять вам сложности в жизни? На что вам стоит обратить внимание?
\t
**Ваши скрытые таланты** - Какие таланты вы боитесь проявить в этот мир? Звезды видят все!
\t
**Ваше предназначение** - Какова миссия вашей души на этой земле согласно вашей астрологической карте?
\t
**Любовь и совместимость** - С какими знаками зодиака у вас могут сложиться отношения, которых вы заслуживаете?
\t
**Ошибки в отношениях** - Почему у вас могут возникнуть трудности в отношениях с партнером? Какие ошибки вы совершаете в отношениях?
\t
**Сексуальная жизнь** - Каковы ваши сексуальные желания и потребности с точки зрения астрологии? Какой тип партнера, который вам больше всего подходит? С какими знаками зодиака будет самый лучший секс в вашей жизни?
\t
**Финансовое процветание** - Какие у вас отношения с деньгами? В чем основные ошибки и как вам достичь финансового процветания с удовольствием.
\t
**Дружба** - За какие качества вас любят и ценят ваши друзья? С какими знаками зодиака у вас больше всего хороших отношений в дружбе и работе, а с какими у вас возникают трудности?
\t
**Планеты** - В каких знаках находятся все ваши планеты. Например, Венера - планета любви, где она у вас? Что это значит? Расскажем вам все!
\t
**Астрологический дома** - В астрологии 12 домов, каждый из которых отвечает за свою область вашей жизни. Например, 7-й дом партнерств, брака и т.д. Какие планеты в вашей карте попадают в этот дом? Как это влияет на вас?
    `,
        en: `
## What Else You Can Discover From Your Natal Chart:
**Your uniqueness** - Discover what the stars say about you. A description of your unique personality based on your natal chart.
\t
**Your strengths** - What are your strengths? What do you excel at the most?
\t
**Your weaknesses** - What aspects of your character could cause difficulties in life? What should you pay attention to?
\t
**Your hidden talents** - What talents are you afraid to show to the world? The stars see it all!
\t
**Your purpose** - What is the mission of your soul on this Earth according to your astrological chart?
\t
**Love and compatibility** - With which zodiac signs could you have the relationships you deserve?
\t
**Relationship mistakes** - Why might you encounter difficulties in relationships with a partner? What mistakes do you make in relationships?
\t
**Sexual life** - What are your sexual desires and needs from an astrological perspective? What type of partner suits you best? With which zodiac signs will you have the best sex in your life?
\t
**Financial prosperity** - What is your relationship with money? What are the main mistakes and how can you achieve financial prosperity enjoyably?
\t
**Friendship** - For what qualities do my friends love and value me? With which zodiac signs do I have the best relationships in friendship and work, and with which do I have difficulties?
\t
**Planets** - The signs where all your planets are located. For example, Venus - the planet of love, where is it in your chart? What does this mean? We'll tell you everything!
\t
**Astrological houses** - In astrology, there are 12 houses, each responsible for a different area of your life. For example, the 7th house of partnerships, marriage, etc. Which planets in your chart fall into this house? How does this affect you?`,
    },
    // Offers Review Section
    offer_reviews_headline: {
        pt: "Nossos clientes nos amam",
        es: "Nuestros clientes nos aman",
        ru: "Вот что говорят наши клиенты",
        en: "Here's what our customers are saying",
    },
    offer_reviews_description: {
        pt: "Mais de **20 000** pessoas já receberam seu mapa natal\n**4,9** [star_sign] classificação média de nossos usuários",
        es: "Más de **20 000** personas ya han recibido su carta natal\n**4,9** [star_sign] calificación promedio de nuestros usuarios",
        ru: "Более **20 000** человек уже получили свою карту рождения\nСредняя оценка **4,9** [star_sign] от наших пользователей",
        en: "Over **20 000** people have already received their natal chart\nAverage rating of **4.9** [star_sign] from our users",
    },
    offer_review_name_1: {
        pt: "Raquel Gomes de Almeida",
        es: "Raquel Gomes de Almeida",
        ru: "Анастасия Петрова",
        en: "Priyanka Chopra",
    },
    offer_review_text_1: {
        pt: "Recebi um mapa natal muito detalhado com recomendações pessoais, nem esperava! Ela me ajudou a me entender melhor. Por esse preço é uma dádiva de Deus para todos, recomendo.",
        es: "Recibí una carta natal muy detallada con recomendaciones personales, ¡ni siquiera me lo esperaba! Ella me ayudó a comprenderme mejor. Por este precio es una bendición para todos, lo recomiendo. Recibí una carta natal muy detallada con recomendaciones personales, ¡ni siquiera me lo esperaba! Ella me ayudó a comprenderme mejor. Por este precio es una bendición para todos, lo recomiendo.",
        ru: "Я получила очень подробную натальную карту с персональными рекомендациями, я даже не ожидала такого! Она помогла мне лучше понять себя. За такую цену это просто находка, всем рекомендую!",
        en: "I received a very detailed natal chart with personalized recommendations, I didn't even expect this! It helped me to understand myself better. For such a price it is a godsend, I recommend it to everyone!",
    },
    offer_review_name_2: {
        pt: "Adelaide Branco Higashi",
        es: "Adelaide Branco Higashi",
        ru: "Максим Мельник",
        en: "James Smith",
    },
    offer_review_text_2: {
        pt: "Isso me ajuda a confiar em mim mesmo e em minhas escolhas para o futuro, dando-me segurança com as informações que recebo. Meus objetivos e sonhos vão acontecer e agora confio em mim para fazer conforme a necessidade e o desejo.",
        es: "Esto me ayuda a confiar en mí mismo y en mis elecciones para el futuro, brindándome seguridad con la información que recibo. Mis metas y sueños se harán realidad y ahora confío en mí mismo para hacer lo que necesito y quiero.",
        ru: "Я приобрел расшифровку своей натальной карты и остался поражен обилием информации, представленной в отчете. На 30+ страницах я нашел интересные факты и инсайты, которые раньше даже не мог себе представить. И что удивительно, это обошлось мне в разы дешевле, чем аналогичные услуги, которые я видел ранее. Впечатлен и доволен результатом!",
        en: "I purchased the transcription of my natal chart and was amazed by the abundance of information presented in the report. In 30+ pages I found interesting facts and insights that I could not even imagine before. And surprisingly, it cost me many times cheaper than similar services I had seen before. I am impressed and satisfied with the result!",
    },
    offer_review_name_3: {
        pt: "Neusa Belluci Araújo",
        es: "Neusa Belluci Araújo",
        ru: "Яна Савельева",
        en: "Nomzamo Mbatha",
    },
    offer_review_text_3: {
        pt: "Comprei recentemente e tenho 48 anos. Agora só gostaria de ter comprado isso há 20 anos. Os detalhes que me deu, muito mais do que eu já li sobre mim pessoalmente. Acho que todas as pessoas deveriam comprar um desses para realmente entender onde você se posiciona como indivíduo neste universo perfeitamente criado. Agradeço sinceramente pelo que você me deu. Abençoe sempre!",
        es: "Lo compré hace poco y tengo 48 años. Ahora sólo desearía haber comprado esto hace 20 años. Los detalles que me diste son muchos más de los que he leído sobre mí personalmente. Creo que todo el mundo debería comprar uno de estos para comprender realmente cuál es su posición como individuo en este universo perfectamente creado. Te agradezco sinceramente lo que me has dado. ¡Bendito siempre!",
        ru: "Я недавно приобрела расшифровку своей натальной карты. Мне уже 48 лет и теперь я сожалею только о том, что не сделала этого 20 лет назад. Кажется, что этот отчет для меня писал кто-то, кто уже много лет меня знает! Я убеждена, что каждый человек должен получить расшифровку своей натальной карты, чтобы по-настоящему понять свою личность в этом удивительном мире. Я искренне благодарю вас за эту возможность и всем рекомендую!",
        en: "I recently purchased a transcription of my natal chart. I am 48 years old now and my only regret now is that I didn't do it 20 years ago. It seems that this report was written for me by someone who has known me for many years! I am convinced that everyone should get their natal chart decoded to truly understand their personality in this wonderful world. I sincerely thank you for this opportunity and recommend it to everyone!",
    },
    offer_review_name_4: {
        pt: "Alina Savelyeva",
        es: "Alina Savelyeva",
        ru: "Алина Савельева",
        en: "Alina Savelyeva",
    },
};

import { z } from "zod";
export const PRODUCTS = {
    MAPA_NATAL: "MAPA_NATAL",
    COMPATIBILITY: "COMPATIBILITY",
    COMPATIBILITY_MINI: "COMPATIBILITY_MINI",
    FORECAST_2024: "FORECAST_2024",
    DAILY: "DAILY",
    VEDIC_INSIGHTS: "VEDIC_INSIGHTS",
    VENUS_AWAKENING: "VENUS_AWAKENING",
    SOLAR_RETURN: "SOLAR_RETURN",
};
export const GENERIC_VARIATIONS = {
    VENUS_AWAKENING: "VENUS_AWAKENING",
    MONEY_CODE: "MONEY_CODE",
    SUN_LIGHT_UP: "SUN_LIGHT_UP",
    MARS_ACTIVATION: "MARS_ACTIVATION",
    MERCURY_TALENTS: "MERCURY_TALENTS",
    SYNASTRY: "SYNASTRY",
    SUPERPOWER: "SUPERPOWER",
    WEALTH: "WEALTH",
    LOVE: "LOVE",
    DESTINY: "DESTINY",
    CHILD: "CHILD",
    SOLAR_RETURN: "SOLAR_RETURN",
    MAPA_NATAL: "MAPA_NATAL",
    COMPATIBILITY: "COMPATIBILITY",
    COMPATIBILITY_MINI: "COMPATIBILITY_MINI",
};
export const VARIATIONS = {
    ...GENERIC_VARIATIONS,
    // custom blogger variations
    IRENE_VENUS_BEAUTY: "IRENE_VENUS_BEAUTY",
    COURSE_FOR_YOU: "COURSE_FOR_YOU",
};
export const ProductSchema = z.enum([
    PRODUCTS.COMPATIBILITY,
    PRODUCTS.COMPATIBILITY_MINI,
    PRODUCTS.MAPA_NATAL,
    PRODUCTS.FORECAST_2024,
    PRODUCTS.DAILY,
    PRODUCTS.VEDIC_INSIGHTS,
    PRODUCTS.VENUS_AWAKENING,
    PRODUCTS.SOLAR_RETURN,
]);
export const VariationSchema = z.enum([
    VARIATIONS.VENUS_AWAKENING,
    VARIATIONS.MONEY_CODE,
    VARIATIONS.SUN_LIGHT_UP,
    VARIATIONS.MARS_ACTIVATION,
    VARIATIONS.MERCURY_TALENTS,
    VARIATIONS.SYNASTRY,
    VARIATIONS.SUPERPOWER,
    VARIATIONS.WEALTH,
    VARIATIONS.LOVE,
    VARIATIONS.DESTINY,
    VARIATIONS.CHILD,
    VARIATIONS.IRENE_VENUS_BEAUTY,
    VARIATIONS.COURSE_FOR_YOU,
]);
export var NICHE;
(function (NICHE) {
    NICHE["WesternAstrology"] = "WesternAstrology";
    NICHE["VedicAstrology"] = "VedicAstrology";
})(NICHE || (NICHE = {}));
export const NicheSchema = z.enum([
    NICHE.WesternAstrology,
    NICHE.VedicAstrology,
]);
export const NicheByProduct = {
    [PRODUCTS.MAPA_NATAL]: NICHE.WesternAstrology,
    [PRODUCTS.FORECAST_2024]: NICHE.WesternAstrology,
    [PRODUCTS.DAILY]: NICHE.WesternAstrology,
    [PRODUCTS.COMPATIBILITY]: NICHE.WesternAstrology,
    [PRODUCTS.COMPATIBILITY_MINI]: NICHE.WesternAstrology,
    [PRODUCTS.VENUS_AWAKENING]: NICHE.WesternAstrology,
    [PRODUCTS.SOLAR_RETURN]: NICHE.WesternAstrology,
    [PRODUCTS.VEDIC_INSIGHTS]: NICHE.VedicAstrology,
};
export function getNicheByProduct(product) {
    return NicheByProduct[product] ?? NICHE.WesternAstrology;
}

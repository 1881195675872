import {
  AMPLITUDE_BRANCH_PARAM,
  AMPLITUDE_EVENTS as EVENTS,
  AMPLITUDE_HOST_PARAM,
  AMPLITUDE_LANGUAGE_PARAM,
  AMPLITUDE_PRICE_TYPE_PARAM,
  AMPLITUDE_PRODUCT_ID_PARAM,
  AMPLITUDE_TEST_ID_PARAM,
  type AmplitudeEvent,
  AmplitudeParams,
  PaymentPriceType,
  type ProductType,
} from "@luaroxa/contracts"
import * as Sentry from "@sentry/vue"
import { watchImmediate } from "@vueuse/core"
import * as amplitude from '@amplitude/analytics-browser';
import type { Ref } from "vue"

const active = true

let client: AmplitudeManager | null = null

class AmplitudeManager {
  queue: Array<{ eventName: AmplitudeEvent; params: any }> = [] // The queue for events
  activationPauseMs = 0
  isQueueActive = true
  pauserEventName = EVENTS.QUIZ

  clientId: Ref<string>
  abTestId: Ref<string>
  price: Ref<number>
  language: Ref<string>
  branch: string
  productId: Ref<ProductType>
  bloggerId: Ref<string>
  variationId: Ref<string>
  email: Ref<string>
  countryCode: Ref<string>
  directSale: Ref<boolean>

  constructor() {
    this.branch = import.meta.env.VITE_BRANCH

    const { flowState } = useFlowStore()
    const { inputs } = useInputsStore()
    const { paymentState } = usePaymentStore()

    this.language = computed(() => flowState.language)
    this.clientId = computed(() => flowState.clientId)
    this.abTestId = computed(() => flowState.abTestId)
    this.price = computed(() => paymentState.price)
    this.productId = computed(() => flowState.productId)
    this.bloggerId = computed(() => flowState.bloggerId)
    this.variationId = computed(() => flowState.variationId)
    this.email = computed(() => inputs.email)
    this.countryCode = computed(() => inputs.person.birthData.countryCode)
    this.directSale = computed(() => flowState.directSale)

    if (import.meta.env.PROD) {
      watchImmediate(this.clientId, (clientId) => {
        amplitude.setUserId(clientId)
      })
    }
  }

  logScreenChanged(eventName: AmplitudeEvent | AmplitudeEvent[]) {
    if (Array.isArray(eventName)) {
      eventName.forEach((event) => {
        this.#logAmplitude(event)
      })
      return
    } else {
      this.#logAmplitude(eventName)
    }
  }

  logEvent(eventName: AmplitudeEvent) {
    this.#logAmplitude(eventName)
  }

  logWarmupQuestion(step: number) {
    this.#logAmplitude(EVENTS.QUIZ_WARMUP_QUESTIONS, {
      step: `question_${step}`,
    })
  }

  logQuizPaymentClick(priceType: PaymentPriceType = PaymentPriceType.NORMAL) {
    this.#logAmplitude(EVENTS.QUIZ_PAYMENT_CLICK, {
      [AMPLITUDE_PRICE_TYPE_PARAM]: priceType.toLowerCase(),
    })
  }

  logQuizEmail() {
    this.#logAmplitude(EVENTS.QUIZ_EMAIL)
  }

  logRedirect() {
    this.#logAmplitude(EVENTS.REDIRECT)
  }

  #logAmplitude(eventName: AmplitudeEvent, additionalParams = {}) {
    try {
      const params = {
        [AMPLITUDE_PRODUCT_ID_PARAM]: this.productId.value?.toLowerCase(),
        [AmplitudeParams.blogger]: this.bloggerId.value?.toLowerCase(),
        [AmplitudeParams.variation]: this.variationId.value?.toLowerCase(),
        [AmplitudeParams.directSale]: this.directSale.value,
        [AMPLITUDE_LANGUAGE_PARAM]: this.language.value,
        [AMPLITUDE_HOST_PARAM]: window.location.hostname,
      }
      if (this.abTestId.value) {
        params[AMPLITUDE_TEST_ID_PARAM] = this.abTestId.value
      }
      if (this.branch) {
        params[AMPLITUDE_BRANCH_PARAM] = this.branch
      }
      if (eventName === EVENTS.QUIZ) {
        const utmParams = getUTMParams()
        Object.assign(params, utmParams)
      }
      if (Object.values(additionalParams).length) {
        Object.assign(params, additionalParams)
      }

      if (this.email.value) {
        params["email"] = this.email.value
      }
      if (this.countryCode.value) {
        params[AmplitudeParams.countryCode] =
          this.countryCode.value?.toUpperCase()
      }

      if (eventName === this.pauserEventName && this.activationPauseMs > 0) {
        this.isQueueActive = false
      }
      this.queue.push({ eventName, params })

      if (this.isQueueActive) {
        this.sendPendingEvents()
      } else {
        setTimeout(() => {
          this.isQueueActive = true
          this.sendPendingEvents()
        }, this.activationPauseMs)
      }
    } catch (error) {
      console.error("Error logging Amplitude event", error)
      try {
        Sentry.captureException(error)
      } catch (e) {
        console.error(e)
      }
    }
  }

  sendPendingEvents() {
    while (this.queue.length > 0) {
      const { eventName, params } = this.queue.shift()!
      this.processEvent(eventName, params)
    }
  }

  processEvent(eventName: AmplitudeEvent, params: any) {
    if (import.meta.env.PROD && active) {
      amplitude.logEvent(eventName, params)
    } else {
      // e2e test validates this log messages
      console.log(
        "Canceling: logAmplitudeEvent",
        eventName,
        JSON.stringify(params),
      )
    }
  }
}

export function useAmplitude() {
  return client ?? (client = new AmplitudeManager())
}

function getUTMParams() {
  const urlParams = new URLSearchParams(new URL(window.location.href).search)
  return {
    utm_source: urlParams.get("utm_source") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_content: urlParams.get("utm_content") || "",
    utm_term: urlParams.get("utm_term") || "",
  }
}

import { defineStore, storeToRefs } from "pinia"
import { computed, ref } from "vue"

import { type ScreenKey, type ScreenSequence } from "@/helpers/screen-registry"

// Define a Pinia store inside the composable to keep track of its usage and initialization
const useScreenStore = defineStore("screen", () => {
  const initialized = ref(false)
  const sequence = ref<ScreenSequence>(null)
  const order = ref<ScreenKey[]>([])
  const step = ref(0)
  const warmupStep = ref(0)
  const errorMessages = ref([])
  const infoMessages = ref([])
  const pdfVisible = ref(false)

  return {
    initialized,
    sequence,
    step,
    order,
    warmupStep,
    errorMessages,
    infoMessages,
    pdfVisible,
  }
})

export function useScreens(newSequence?: ScreenSequence) {
  const screenStore = useScreenStore()
  const {
    initialized,
    sequence,
    order,
    step,
    warmupStep,
    errorMessages,
    infoMessages,
    pdfVisible,
  } = storeToRefs(screenStore)

  const amplitude = useAmplitude()

  // Initialize the store and screens if sequence is provided on the first call
  if (
    newSequence &&
    Object.keys(newSequence).length > 0 &&
    !initialized.value
  ) {
    sequence.value = newSequence
    order.value = Object.keys(newSequence) as ScreenKey[]
    step.value = -1
    initialized.value = true
  }

  const checkIfSequenceIsInitialized = () => {
    if (!initialized.value) {
      throw new Error(
        "useScreens must be initialized with a screen order array on the first call.",
      )
    }
  }

  // Navigation methods
  const goToNextScreen = () => {
    checkIfSequenceIsInitialized()
    if (step.value < order.value.length - 1) {
      goToScreen(order.value[step.value + 1])
    }
  }

  const goToPreviousScreen = () => {
    checkIfSequenceIsInitialized()
    if (step.value > 0) {
      goToScreen(order.value[step.value - 1])
    }
  }

  const goToScreen = (screen: ScreenKey) => {
    checkIfSequenceIsInitialized()
    const to = order.value.indexOf(screen)
    if (to !== -1) {
      step.value = to
      onGoToScreen(screen)
    } else {
      console.warn(`Screen "${screen}" not found.`)
    }
  }

  const onGoToScreen = (screen: ScreenKey) => {
    infoMessages.value = []
    errorMessages.value = []

    const { amplitudeEvent } = sequence.value[screen]
    if (amplitudeEvent) {
      amplitude.logScreenChanged(amplitudeEvent)
    }
  }

  const setWarmupStep = (index: number) => {
    warmupStep.value = index
    amplitude.logWarmupQuestion(warmupStep.value)
  }

  const currentScreen = computed(() => order.value[step.value])

  const getScreenIndex = (screen: ScreenKey) => order.value.indexOf(screen)

  const startOver = () => {
    goToScreen(order.value[1])
  }

  const resetMessages = () => {
    errorMessages.value = []
    infoMessages.value = []
  }

  const setPdfVisible = (value: boolean) => (pdfVisible.value = value)

  return {
    goToNextScreen,
    goToPreviousScreen,
    goToScreen,
    getScreenIndex,
    nextStep: goToNextScreen,
    setStep: (step: number) => {
      goToScreen(order.value[step])
    },
    prevStep: goToPreviousScreen,
    setWarmupStep,
    startOver,
    currentScreen,
    order,
    step,
    warmupStep,
    infoMessages,
    errorMessages,
    resetMessages,
    setPdfVisible,
    pdfVisible,
    total: computed(() => order.value.length),
  }
}

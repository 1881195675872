import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/app/LoginView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/app/ProfileView.vue"),
  },
  {
    path: "/profile/edit",
    name: "edit-profile",
    component: () => import("@/views/app/EditProfileView.vue"),
  },
  {
    path: "/set-password/:id",
    name: "set-password",
    component: () => import("@/views/app/SetPasswordView.vue"),
  },
]

export default routes

import { z } from "zod";
import { ZPerson } from "./natal-chart.interfaces";
import { ZQuizBaseRequest } from "./quiz.interfaces";
export const ZPresaveCompatibilityMiniQuizRequest = ZQuizBaseRequest.extend({
    preferenceId: z.string(),
    userData: z.object({
        person: ZPerson,
        email: z.string().email(),
        phone: z.string().optional(),
        demoInterpretation: z.string().optional(),
        answers: z.any(),
    }),
});

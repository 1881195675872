import { translate as translateWithLang } from "@luaroxa/contracts"
import { getLanguage } from "@luaroxa/shared-vue"

export const zeroPad = (num: number | string) => {
  return String(num).padStart(2, "0")
}

export function markdownToHtmlLink(mdLink: string): string {
  if (!mdLink) return ""
  const mdLinkRegEx = /\[(.*?)\]\((.*?)\)/g
  return mdLink.replace(
    mdLinkRegEx,
    '<a target="_blank" class="underline" href="$2">$1</a>',
  )
}

function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export function deepMerge(target, source) {
  if (isObject(target) && isObject(source)) {
    return {
      ...target,
      ...Object.entries(source).reduce((acc, [key, value]) => {
        acc[key] =
          isObject(value) && key in target
            ? deepMerge(target[key], value)
            : value
        return acc
      }, {}),
    }
  }
  return source
}

export const UI_URL = (() => {
  const url = `${window.location.origin}/${import.meta.env.VITE_BASE_URL}`
  return url.replace(/([^:])\/\/+/g, "$1/")
})()

export function getLocationContext(): {
  branch: string
  bloggerId: string
  productId: string
  subpage: string
} {
  const branch = import.meta.env.VITE_BRANCH
  const [bloggerId, productId, subpage] = location.pathname
    .split("/")
    .filter(Boolean)
    .slice(["main", "local"].includes(branch) ? 0 : 1)

  return { branch, bloggerId, productId, subpage }
}
window["getLocationContext"] = getLocationContext

export const getBaseQuizUrl = () => {
  const { bloggerId, productId } = getLocationContext()
  return `${UI_URL}${bloggerId}/${productId}`
}
window["getBaseQuizUrl"] = getBaseQuizUrl

export const LANG = getLanguage()

export function formatNumberToTwoDigits(value: number) {
  return value.toString().padStart(2, "0")
}

export function capitalizeString(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const nonNullProps = <T extends object, K extends keyof T>(
  state: T,
  keys: K[],
): Partial<T> => {
  const filteredEntries = keys
    .filter((key) => state[key] !== null)
    .map((key) => [key, state[key]])

  return Object.fromEntries(filteredEntries) as Partial<T>
}

export function openLinkInNewTab(url: string) {
  window.open(url, "_blank")
}

export const translate = (translateKey: string) => {
  return translateWithLang(translateKey, LANG)
}

export function redirectLegacyToNew() {
  // Get the current URL
  const currentUrl = window.location.href

  // Create a URL object for easy manipulation
  const url = new URL(currentUrl)

  // Define the list of branches
  const branches = [
    "serge",
    "dev",
    "orion",
    "lyra",
    "ursa",
    "atlas",
    "draco",
    "matrix",
    "neptune",
    "phoenix",
    "saturn",
    "sirius",
    "taurus",
    "uranus",
    "vega",
    "virgo",
  ]

  // Split the path into segments, filtering out empty entries
  const pathSegments = url.pathname.split("/").filter((p) => p)

  // Check if the first segment is 'quiz'
  if (pathSegments[0] === "quiz") {
    // Remove 'quiz' from the segments
    pathSegments.shift()

    // Check if the next segment is a known branch
    if (branches.includes(pathSegments[0])) {
      // Place 'lua' after the branch name
      pathSegments.splice(1, 0, "lua")
    } else {
      // If no branch is detected or if it's the main branch
      pathSegments.unshift("lua") // Add 'lua' at the start
    }

    // Reassemble the path ensuring no leading double slash
    url.pathname = `/${pathSegments.join("/")}`

    // Redirect to the new URL
    window.location.replace(url.href)
  }
}

interface CdnImageProps {
  url: string
  quality?: number
  width?: number
  height?: number
  backgroundColor?: string
  hue?: string
}

export function cdnImage({
  url,
  quality = 50,
  width = null,
  height = null,
  backgroundColor = null,
  hue = null,
}: CdnImageProps): string {
  if (!url) {
    return null
  }
  // check if has transformations already
  if (!url.includes("/upload/")) {
    return url
  }
  const isPng = url.endsWith(".png")
  const transformations = []

  // Add quality and optionally width and height
  transformations.push(`q_${quality}`)
  if (width) transformations.push(`w_${width}`)
  if (height) transformations.push(`h_${height}`)
  if (hue) transformations.push(`e_hue:${hue}`)

  let newImageUrl = url.replace(
    /\/upload\//,
    `/upload/${transformations.join(",")}/`,
  )

  // If the image is a PNG and a background color is specified, convert to JPEG and add the background color
  if (isPng && backgroundColor) {
    newImageUrl = newImageUrl.replace(".png", ".jpg") // Change the extension from .png to .jpg
    newImageUrl = newImageUrl.replace(
      "/upload/",
      `/upload/b_rgb:${backgroundColor},`,
    ) // Add background color before other transformations
  }

  return newImageUrl
}

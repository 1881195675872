import { z } from "zod";
import { LANGUAGE } from "./language.enum";
export const ZReportPage = z.object({
    layout: z.string(),
    title: z.string().optional(),
    subTitle: z.string().optional(),
    subTitle2: z.string().optional(),
    body: z.string().optional(),
    footer: z.string().optional(),
    prompt: z.string().default(""),
    image: z.string().optional(),
    "bg:overlay": z
        .union([z.literal("stars"), z.literal("placeholder")])
        .or(z.string())
        .optional(),
    "bg:color": z.string().optional(),
    "text:color": z.string().optional(),
    "title:font": z.string().optional(),
    "title:fontSize": z.string().optional(),
    "title:fontWeight": z.string().optional(),
    "subTitle:fontSize": z.string().optional(),
    "subTitle:fontWeight": z.string().optional(),
    "subTitle2:fontSize": z.string().optional(),
    "subTitle2:fontWeight": z.string().optional(),
    "body:font": z.string().optional(),
    "body:fontSize": z.string().optional(),
    "body:fontWeight": z.string().optional(),
    button: z.string().optional(),
    "button:font": z.string().optional(),
    "button:color": z.string().optional(),
    "button:fontSize": z.string().optional(),
    "button:fontWeight": z.string().optional(),
    button2: z.string().optional(),
    "button2:font": z.string().optional(),
    "button2:color": z.string().optional(),
    "button2:fontSize": z.string().optional(),
    "button2:fontWeight": z.string().optional(),
    logo: z
        .union([z.literal("zodiaque"), z.literal("placeholder")])
        .or(z.string())
        .optional(),
    footerLogo: z
        .union([z.literal("zodiaque-white"), z.literal("placeholder")])
        .or(z.string())
        .optional(),
    footerInstagram: z.string().optional(),
    font: z.string().optional(),
    fontSize: z.string().optional(),
    completion: z.string().optional(),
    metadata: z.string().optional(),
});
export const ZReportPages = z.array(ZReportPage);
export const ZReportPayload = z.object({
    pages: ZReportPages,
    metadata: z.object({
        language: z.nativeEnum(LANGUAGE),
    }),
});

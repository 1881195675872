import { z } from "zod";
export const AB_GROUPS = {
    ORION: "ORION",
    LYRA: "LYRA",
    URSA: "URSA",
};
export const AbGroupSchema = z.enum([
    AB_GROUPS.ORION,
    AB_GROUPS.LYRA,
    AB_GROUPS.URSA,
]);

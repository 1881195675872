import { defineStore } from "pinia"
import { ref } from "vue"

export const useAuthStore = defineStore("auth", () => {
  const accessToken = ref(localStorage.getItem("accessToken") || null)
  const refreshToken = ref(localStorage.getItem("refreshToken") || null)

  const updateStorage = (key: string, token: string | null) => {
    localStorage.setItem(key, token)
    if (key === "accessToken") {
      accessToken.value = token
    } else if (key === "refreshToken") {
      refreshToken.value = token
    }
  }

  const clearStorage = (key: string) => {
    localStorage.removeItem(key)
    if (key === "accessToken") {
      accessToken.value = null
    } else if (key === "refreshToken") {
      refreshToken.value = null
    }
  }

  return {
    accessToken,
    refreshToken,
    isAuthenticated: computed(() => !!accessToken.value),
    setTokens: (newAccessToken: string, newRefreshToken: string) => {
      updateStorage("accessToken", newAccessToken)
      updateStorage("refreshToken", newRefreshToken)
    },
    clearTokens: () => {
      clearStorage("accessToken")
      clearStorage("refreshToken")
    },
    setAccessToken: (token: string | null) =>
      updateStorage("accessToken", token),
    setRefreshToken: (token: string | null) =>
      updateStorage("refreshToken", token),
  }
})

import { updateBodyClasses } from "@luaroxa/shared-vue"
import * as Sentry from "@sentry/vue"
import type { RouteRecordRaw } from "vue-router"
import { createRouter, createWebHistory } from "vue-router"

import { redirectLegacyToNew } from "../helpers"
import profileRoutes from "./profile-routes"
import quizAdminRoutes from "./quiz-admin-routes"
import quizBloggersVariations from "./quiz-bloggers-variations"
import quizRoutes from "./quiz-routes"
import quizSystemRoutes from "./quiz-system-routes"

redirectLegacyToNew()

const routes: RouteRecordRaw[] = [
  ...quizRoutes,

  ...quizBloggersVariations,

  ...profileRoutes,

  ...quizAdminRoutes,

  ...quizSystemRoutes,
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
})

router.onError((error) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Loading chunk failed") ||
    error.message.includes("Load failed")
  ) {
    const path = router.currentRoute.value.fullPath
    window.location.href = path
  } else {
    try {
      Sentry.captureException(error)
    } catch (e) {
      console.error("Error logging to Sentry:", e)
      console.error("Original error:", error)
    }
  }
})

// Update body classes based on route
router.beforeEach((to, from, next) => {
  updateBodyClasses(to.path)
  next()
})

// Auth guard
router.beforeEach((to, from, next) => {
  const { isAuthenticated } = useAuthStore()
  const securedPages = ["/profile", "/profile/edit"]
  const authRequired = securedPages.includes(to.path)
  if (authRequired && !isAuthenticated) {
    next("/login")
  } else {
    next()
  }
})

export default router

<script setup lang="ts">
import { RouterView } from "vue-router"

import { translate } from "./helpers"

const {
  isSuccess,
  isLoading,
  isIdle,
  mutate: initializeQuizApi,
} = useInitializeQuizApi()

onMounted(() => {
  initializeQuizApi()
})
</script>

<template>
  <template v-if="isSuccess">
    <RouterView />
  </template>

  <div
    v-else
    class="flex h-full items-center justify-center"
  >
    <div v-if="isLoading || isIdle">
      <SmallSpinner />
    </div>

    <div
      v-else
      class="text-error-500 text-center"
    >
      {{
        translate("GenericError") ??
        "There was an error. Please try again later."
      }}
    </div>
  </div>
</template>

import { z } from "zod";
const ZPlanetDetailWithOptionalPlanetName = z.object({
    planet_name: z.string().optional(),
    sign_name: z.string(),
    house_position: z.number(),
});
const ZDescriptionSchema = z.object({
    title: z.string().optional(),
    text: z.string().optional(),
});
const ZPdfBirthSchema = z.object({
    year: z.number().min(1900).max(2200),
    month: z
        .union([z.number().min(0).max(12), z.string().length(2)])
        .transform((value) => value.toString().padStart(2, "0")),
    date: z
        .union([z.number().min(1).max(31), z.string().length(2)])
        .transform((value) => value.toString().padStart(2, "0")),
    time: z.string().transform((str) => str.replace("\n", "")),
    country: z.string().transform((str) => str.replace("\n", "")),
    city: z.string().transform((str) => str.replace("\n", "")),
});
export const ZMapaNatalPdfPayload = z.object({
    name: z.string(),
    birth: ZPdfBirthSchema,
    sol: ZPlanetDetailWithOptionalPlanetName,
    lua: ZPlanetDetailWithOptionalPlanetName,
    ascendente: ZPlanetDetailWithOptionalPlanetName,
    vênus: ZPlanetDetailWithOptionalPlanetName,
    mercúrio: ZPlanetDetailWithOptionalPlanetName,
    marte: ZPlanetDetailWithOptionalPlanetName,
    júpiter: ZPlanetDetailWithOptionalPlanetName,
    saturno: ZPlanetDetailWithOptionalPlanetName,
    urano: ZPlanetDetailWithOptionalPlanetName,
    netuno: ZPlanetDetailWithOptionalPlanetName,
    description_1: ZDescriptionSchema.optional(),
    description_2: ZDescriptionSchema.optional(),
    description_3: ZDescriptionSchema.optional(),
    description_4: ZDescriptionSchema.optional(),
    description_5: ZDescriptionSchema.optional(),
    description_6: ZDescriptionSchema.optional(),
    description_7: ZDescriptionSchema.optional(),
    description_8: ZDescriptionSchema.optional(),
    description_9: ZDescriptionSchema.optional(),
    description_10: ZDescriptionSchema.optional(),
});

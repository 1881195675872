if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replace) {
    // Check if search is a regex
    if (search instanceof RegExp) {
      // Ensure global flag is set
      if (!search.flags.includes("g")) {
        throw new Error("replaceAll called with a non-global RegExp argument")
      }
      return this.replace(search, replace)
    }
    // Escape special characters for non-regex search strings
    return this.replace(
      new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
      replace,
    )
  }
}

if (!Promise.allSettled) {
  Promise.allSettled = function (promises) {
    return Promise.all(
      promises.map((p) =>
        Promise.resolve(p) // This ensures that non-promise values are converted into promises
          .then((value) => ({
            status: "fulfilled",
            value,
          }))
          .catch((reason) => ({
            status: "rejected",
            reason,
          })),
      ),
    )
  }
}

import type {
  IChartRequest,
  IChartResponse,
  ICompatibilityDemoRequest,
  ICompatibilityDemoResponse,
  ICreatePreferenceRequest,
  ICreateRoboxCheckoutRequest,
  ICreateYoomoneyCheckoutRequest,
  IDailyQuizStartRequest,
  IMapaNatalDemoResponse,
  INatalDemoRequest,
  IPagarmeOrderRequest,
  IPagarmeOrderResponse,
  IPaymentTransaction,
  IPresaveCompatibilityMiniQuizRequest,
  IPresaveCompatibilityQuizRequest,
  IPresaveDailyRequest,
  IPresaveNatalQuizRequest,
  IPresaveQuizResponse,
  IQuizStartRequest,
  IQuizStartResponse,
  ISaveAnswersRequest,
  IStripeOrderRequest,
  ISubmitCompatibilityQuizRequest,
  ISubmitDailyRequest,
  ISubmitNatalQuizRequest,
  ISubmitQuizResponse,
  IUser,
  IVedicChartResponse,
  IVedicInsightsDemoRequest,
  LANGUAGE,
  ProductType,
  RecoveryEntry,
  VariationType,
} from "@luaroxa/contracts"
import axios from "axios"

export type QuizApiClient = ReturnType<typeof createQuizApiClient>

export interface ApiClientArgs {
  apiUrl: string
}

export function createQuizApiClient({ apiUrl }: ApiClientArgs) {
  const client = axios.create({
    baseURL: apiUrl,
    timeout: 0,
    headers: {},
  })

  async function startCompatibilityMiniQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("compatibility-mini/start", json)
    return data
  }

  async function presaveCompatibilityMiniQuiz(
    json: IPresaveCompatibilityMiniQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("compatibility-mini/presave", json)
    return data
  }

  async function submitCompatibilityMiniQuiz(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("compatibility-mini/submit", json)
    return data
  }

  async function startMapaNatalQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("mapa-natal/start", json)
    return data
  }

  async function getMapaDemo(
    json: INatalDemoRequest,
  ): Promise<IMapaNatalDemoResponse> {
    const { data } = await client.post("mapa-natal/demo", json)
    return data
  }

  async function getNatalChart(json: IChartRequest): Promise<IChartResponse> {
    const { data } = await client.post("astro-api/chart", json)
    return data
  }

  async function getSolarReturnChart(
    json: IChartRequest,
  ): Promise<IChartResponse> {
    const { data } = await client.post("astro-api/solar-return-chart", json)
    return data
  }

  async function presaveMapaNatalQuiz(
    json: IPresaveNatalQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("mapa-natal/presave", json)
    return data
  }

  async function submitMapaNatalQuiz(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("mapa-natal/submit", json)
    return data
  }

  async function startCompatibilityQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("compatibility/start", json)
    return data
  }

  async function getCompatibilityDemo(
    json: ICompatibilityDemoRequest,
  ): Promise<ICompatibilityDemoResponse> {
    const { data } = await client.post("compatibility/demo", json)
    return data
  }

  async function presaveCompatibilityQuiz(
    json: IPresaveCompatibilityQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("compatibility/presave", json)
    return data
  }

  async function submitCompatibilityQuiz(
    json: ISubmitCompatibilityQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("compatibility/submit", json)
    return data
  }

  async function startForecast2024(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("forecast-2024/start", json)
    return data
  }

  async function presaveForecast2024(
    json: IPresaveNatalQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("forecast-2024/presave", json)
    return data
  }

  async function submitForecast2024(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("forecast-2024/submit", json)
    return data
  }

  async function startDailyQuiz(
    json: IDailyQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("daily/start", json)
    return data
  }

  async function presaveDailyQuiz(
    json: IPresaveDailyRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("daily/presave", json)
    return data
  }

  async function submitDailyQuiz(
    json: ISubmitDailyRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("daily/submit", json)
    return data
  }

  async function saveUserAnswers(json: ISaveAnswersRequest): Promise<IUser> {
    const { data } = await client.post("user/answers", json)
    return data
  }

  async function createMercadopagoPreference(
    json: ICreatePreferenceRequest,
  ): Promise<{ preferenceId: string; redirectUrl: string }> {
    const { data } = await client.post("mercadopago/preference/create", json)
    return data
  }

  async function createPagarmeOrder(
    json: IPagarmeOrderRequest,
  ): Promise<IPagarmeOrderResponse> {
    const { data } = await client.post("pagarme/order/create", json)
    return data
  }

  async function stripeCheckout(
    json: IStripeOrderRequest,
  ): Promise<{ url: string }> {
    const { data } = await client.post("stripe/checkout", json)
    return data
  }

  async function createYoomoneyCheckout(
    json: ICreateYoomoneyCheckoutRequest,
  ): Promise<{ preferenceId: string; redirectUrl: string }> {
    const { data } = await client.post("yoomoney/checkout/create", json)
    return data
  }

  async function createRoboxCheckout(
    json: ICreateRoboxCheckoutRequest,
  ): Promise<{ preferenceId: string; redirectUrl: string }> {
    const { data } = await client.post("robox/create", json)
    return data
  }

  async function createRoboxKzCheckout(
    json: ICreateRoboxCheckoutRequest,
  ): Promise<{ preferenceId: string; redirectUrl: string }> {
    const { data } = await client.post("robox-kz/create", json)
    return data
  }

  async function getReportRecoveryList(
    clientId: string,
  ): Promise<RecoveryEntry[]> {
    const { data } = await client.post("report-recovery/get-list", { clientId })
    return data
  }

  async function recoverReport(
    sessionId: string,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("report-recovery/generate", {
      sessionId,
    })
    return data
  }

  async function adminPay(
    transaction: IPaymentTransaction,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("mercadopago/admin-pay", transaction)
    return data
  }

  async function refreshCache(params: {
    bloggerId: string
    productId: ProductType
    variationId: VariationType | null
    branch: string
    language: LANGUAGE
  }): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("cache/refresh", params)
    return data
  }

  async function getVedicChart(
    json: IChartRequest,
  ): Promise<IVedicChartResponse> {
    const { data } = await client.post("astro-api/chart/vedic", json)
    return data
  }

  async function startVedicInsightsQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("vedic-insights/start", json)
    return data
  }

  async function getVedicInsightsDemo(
    json: IVedicInsightsDemoRequest,
  ): Promise<IMapaNatalDemoResponse> {
    const { data } = await client.post("vedic-insights/demo", json)
    return data
  }

  async function presaveVedicInsightsQuiz(
    json: IPresaveNatalQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("vedic-insights/presave", json)
    return data
  }

  async function submitVedicInsightsQuiz(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("vedic-insights/submit", json)
    return data
  }

  // VENUS AWAKENING
  async function startVenusAwakeningQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("venus-awakening/start", json)
    return data
  }

  async function getVenusAwakeningDemo(
    json: INatalDemoRequest,
  ): Promise<IMapaNatalDemoResponse> {
    const { data } = await client.post("venus-awakening/demo", json)
    return data
  }

  async function presaveVenusAwakeningQuiz(
    json: IPresaveNatalQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("venus-awakening/presave", json)
    return data
  }

  async function submitVenusAwakeningQuiz(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("venus-awakening/submit", json)
    return data
  }

  // SOLAR RETURN
  async function startSolarReturnQuiz(
    json: IQuizStartRequest,
  ): Promise<IQuizStartResponse> {
    const { data } = await client.post("solar-return/start", json)
    return data
  }

  async function getSolarReturnDemo(
    json: INatalDemoRequest,
  ): Promise<IMapaNatalDemoResponse> {
    const { data } = await client.post("solar-return/demo", json)
    return data
  }

  async function presaveSolarReturnQuiz(
    json: IPresaveNatalQuizRequest,
  ): Promise<IPresaveQuizResponse> {
    const { data } = await client.post("solar-return/presave", json)
    return data
  }

  async function submitSolarReturnQuiz(
    json: ISubmitNatalQuizRequest,
  ): Promise<ISubmitQuizResponse> {
    const { data } = await client.post("solar-return/submit", json)
    return data
  }

  return {
    startForecast2024,
    submitForecast2024,
    presaveForecast2024,
    startMapaNatalQuiz,
    getMapaDemo,
    createMercadopagoPreference,
    presaveMapaNatalQuiz,
    submitMapaNatalQuiz,
    startCompatibilityQuiz,
    getCompatibilityDemo,
    presaveCompatibilityQuiz,
    submitCompatibilityQuiz,
    getNatalChart,
    createPagarmeOrder,
    stripeCheckout,
    createYoomoneyCheckout,
    createRoboxCheckout,
    createRoboxKzCheckout,

    startCompatibilityMiniQuiz,
    presaveCompatibilityMiniQuiz,
    submitCompatibilityMiniQuiz,

    startDailyQuiz,
    presaveDailyQuiz,
    submitDailyQuiz,
    saveUserAnswers,

    getReportRecoveryList,
    recoverReport,

    adminPay,
    refreshCache,

    getVedicChart,
    getSolarReturnChart,

    // NATAL CHART VEDIC
    startVedicInsightsQuiz,
    getVedicInsightsDemo,
    presaveVedicInsightsQuiz,
    submitVedicInsightsQuiz,

    // VENUS AWAKENING
    startVenusAwakeningQuiz,
    getVenusAwakeningDemo,
    presaveVenusAwakeningQuiz,
    submitVenusAwakeningQuiz,

    // SOLAR RETURN
    startSolarReturnQuiz,
    getSolarReturnDemo,
    presaveSolarReturnQuiz,
    submitSolarReturnQuiz,
  }
}

export var PaymentPriceType;
(function (PaymentPriceType) {
    PaymentPriceType["NORMAL"] = "NORMAL";
    PaymentPriceType["DOWNSALE"] = "DOWNSALE";
})(PaymentPriceType || (PaymentPriceType = {}));
export var PaymentSystem;
(function (PaymentSystem) {
    PaymentSystem["STRIPE"] = "stripe";
    PaymentSystem["MERCADOPAGO"] = "mercadopago";
    PaymentSystem["PAGARME"] = "pagarme";
    PaymentSystem["YOOMONEY"] = "yoomoney";
    PaymentSystem["ROBOX"] = "robox";
    PaymentSystem["ROBOX_KZ"] = "robox_kz";
    PaymentSystem["FREE"] = "free";
})(PaymentSystem || (PaymentSystem = {}));
